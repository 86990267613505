import { gql } from 'graphql-request';

export const getStateQuery = (pincode) => gql`
  {
    getState(zipcode:"${pincode}"){
      city
      state
      state_code
      region_id
    }
  }
`;
