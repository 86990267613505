import { parseEmail, parseMobileNumber } from "src/server/utils";

declare var webengage;

class WebEngage {
  userLogin = async (
    {
      email = '',
      phone = '',
      first_name = '',
      last_name = '',
      mode = '',
      user_id = '',
      modeSource = {
        source: '',
        page: '',
      },
    },
    user = 'Guest'
  ) => {
    console.log('USER:', user);
    email = parseEmail(email)
    phone = parseMobileNumber(phone)

    if (webengage && webengage.user) {
      // const isLoggedIn = localStorage.getItem('login:token');
      if (email) {
        // -- Customer ID is not fetched in magento so phone referred to as unique id
        // webengage.user.login(id);
        webengage.user.login(email);
      } else {
        const id = webengage?.user?.getAnonymousId();
        webengage?.user?.login(id);
      }

      this.setUserAttribute('user_type', user);

      if (phone) this.setPhone(phone);

      if (first_name && last_name)
        this.setName({
          first_name,
          last_name,
        });

      if (mode == 'login' && modeSource?.source) {
        this.setUserAttribute('User Logged In', modeSource);
        this.event('User Logged In', {
          phone,
          email,
          first_name,
          last_name,
          user_id,
          modeSource,
        });
      }

      if (mode == 'signup' && modeSource?.source) {
        this.setUserAttribute('User Signed Up', modeSource);
        this.event('User Signed Up', {
          phone,
          email,
          first_name,
          last_name,
          user_id,
          modeSource,
        });
      }

      if (email) this.setEmail(email);
    }
  };

  setPhone = (phone: string) => this.setUserWEAttribute('we_phone', parseMobileNumber(phone));
  setEmail = (email: string) => this.setUserWEAttribute('we_email', parseEmail(email));

  signUpNewsLetter = (email: string) =>
    this.setUserAttribute('Signed Up For News Letter', parseEmail(email));

  setName = ({
    first_name,
    last_name,
  }: {
    first_name: string;
    last_name: string;
  }) => {
    this.setUserWEAttribute('we_first_name', first_name);
    this.setUserWEAttribute('we_last_name', last_name);
  };

  setUserAttribute = (key: string, value: any) => {
    webengage?.user && webengage.user.setAttribute(key, value);
    console.log('WE USER: event', { key, value });
  };

  setMultipleUserAttribute = (data: any) => {
    if (Array.isArray(data) && webengage?.user) {
      for (let { key, value } of data) {
        webengage.user.setAttribute(key, value);
        console.log('WE USER: event', { key, value });
      }
    }
  };

  setUserWEAttribute = (key: string, value: string) =>
    webengage?.user && webengage.user.setAttribute(key, value);

  //WebEngage - Trigger user to be logout and trackked on dashboard
  // userLogout = () => webengage && webengage.user && webengage.user.logout();
  userLogout = () => {
    console.log('WE USER: Logout');
    webengage && webengage.user && webengage.user.logout();
  };

  event = (key: string, payload: any) => {
    // this.userLogin({});
    webengage && webengage.track(key, payload);
    console.log('WE event', { key, payload });
  };

  multiEvent = (data: any) => {
    if (Array.isArray(data) && webengage?.track) {
      for (let { key, payload } of data) {
        webengage.track(key, payload);
        console.log('WE event', { key, payload });
      }
    }
  };
}

const we = new WebEngage();

export default we;
