import './product-tile.scss'
import { Image } from '@/theme/components/Image'
import RatingResult from '@/theme/components/Ratings/RatingResult'
import Form from '../Form'
import { Currency } from '@/services/helpers/currency'
import useProductContext, {
  ProductContextProvider,
} from '@/theme/route/ProductPage/useProductContext'
import {
  isProductGift,
  isProductBundle,
  isProductConfigurable,
  isProductSimple,
} from '@/services/helpers/isProductType'
import ConfigurableProductV2 from '../ConfigurableProductV2'
import AddToCartButton from '../ProductMain/AddToCartButton'
import Link from '@/core/Link'
import useAuthContext from '../Contexts/AuthContext'
import useCartContext from '../CartWrapper/useCartContext'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import {
  addProductsToCompareList,
  createCompareList,
  removeProductsFromWishlist,
} from 'src/graphql/mutations/customermutation'
import PersistanceStorage from '@/utils/storage'
import { useEffect, useState } from 'react'
import { compareList } from 'src/graphql/queries/customerQuery'
import { useAppContext } from '@/core/App/AppContext'
import { useRouterContext } from '@/core/Router/RouterContext'
import { useGraphQLMutation } from '@/theme/lib/useGraphQLQuery'
import { gql } from 'graphql-request'
import Button from '../Button'
import we from 'src/services/webengage'
import RatingSummary from '../Ratings/RatingSummary'

export const ProductTileLoader = (props) => {
  return (
    <div className="product-tile-placeholder">
      <div className="image-placeholder skeleton-box"></div>
      <div className="name-placeholder skeleton-box"></div>
      <div className="price-placeholder skeleton-box"></div>
      <div className="tocart-placeholder skeleton-box"></div>
    </div>
  )
}

const ProductPrice = (props) => {
  const [{ cartItem, price, amount_off, list_price, percent_off }] =
    useProductContext()
  const finalPrice = price * cartItem.quantity
  const finalListPrice = list_price * cartItem.quantity

  return (
    <>
      <strong className="price">{Currency({ money: finalPrice })}/-</strong>
      {amount_off > 0 ? (
        <>
          <span className="price percent-off">
            {Math.round(percent_off)}% Off
          </span>
        </>
      ) : null}
      {amount_off > 0 ? (
        <>
          <span className="price old-price">
            {Currency({ money: finalListPrice })}
          </span>
        </>
      ) : null}
    </>
  )
}

const WishlistButton = (props) => {
  const [, { getCartItem }] = useProductContext()
  const [, , { addToWishlist }] = useAuthContext()

  return (
    <svg
      id="icon-heart"
      viewBox="-2 -2 34 34"
      className="towishlist"
      strokeWidth={5}
      onClick={(e) => {
        e.preventDefault()
        addToWishlist(getCartItem())
      }}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="-5.0 -10.0 110.0 135.0"
    >
      <path d="m87.312 19.625c-4.375-4.6562-10.312-7.2812-16.688-7.375-6.3438 0-12.375 2.3438-16.906 6.875l-3.6875 3.7188-3.6875-3.7188c-4.5-4.5-10.5-6.875-16.906-6.8438-6.375 0.09375-12.312 2.7188-16.688 7.375-8.5938 9.125-8.0938 24 1.0938 33.188l34 34c0.59375 0.59375 1.375 0.90625 2.2188 0.90625s1.625-0.34375 2.2188-0.90625l34-34c9.1875-9.1875 9.6875-24.094 1.0938-33.188zm-5.5312 28.781-31.781 31.781-31.781-31.781c-6.8125-6.8125-7.25-17.812-0.96875-24.5 3.2188-3.4062 7.5625-5.3438 12.25-5.4062h0.25c4.5938 0 8.875 1.7812 12.125 5.0312l5.9062 5.9688c1.1875 1.1875 3.25 1.1875 4.4375 0l5.9062-5.9375c3.3125-3.3125 7.6875-5 12.375-5.0312 4.6875 0.0625 9.0312 2 12.25 5.4062 6.2812 6.6875 5.875 17.656-0.96875 24.5z" />
    </svg>
  )
}

const ProductTile = (props) => {
  const {
    name,
    price,
    special_price,
    image,
    sku,
    discount,
    rating_summary,
    review_count,
    pack_size = [],
    url_key,
    url_prefix,
    __typename,
    defaultCartItem,
    short_description,
    wishlist_id,
    wishlist_item_id,
  } = props
  const [, , { addToWishlist, addToCompareList, getCompareList, reloadLogin }] =
    useAuthContext()
  const [{ mitter }] = useAppContext()
  const [isGrid, setIsGrid] = useState(false)
  const isGiftProduct = isProductGift(props)
  const [{ loading, error, data: removeProduct }, mutate] = useGraphQLMutation(
    gql`
      ${removeProductsFromWishlist}
    `,
    null,
    { authorized: true }
  )

  if (removeProduct) {
    // mitter.emit('RELOAD_LOGIN')
    we.event('Removed from wishlist', {
      'Product ID': `${props?.uid}`,
      'Product Id': `${sku}`,
      'Product Name': `${props?.name}`,
      // 'Category Id':`${props?.categories?.map((i)=>(i?.uid))}`,
      'Category Id': ``,
      'Category Name': `${props?.categories?.map((i) => i?.name)}`,
      'SubCategory Name': '',
      'Sub Category Id': '',
      Brand: 'Moha',
      Manufacturer: 'Moha',
      'Retail Price': props?.price_range?.minimum_price?.final_price?.value,
      discount: props?.price_range?.minimum_price?.discount?.amount_off,
      Price:
        props?.price_range?.minimum_price?.final_price?.value +
        props?.price_range?.minimum_price?.discount?.amount_off,
      Currency: `${props?.price_range?.minimum_price?.final_price?.currency}`,
      Size: '',
      Color: '',
      Image: `${props?.image?.url}`,
    })
    window.location.reload()
  }

  useEffect(() => {
    mitter.on('isGrid', setIsGrid)
  }, [])
  return (
    <ProductContextProvider product={props}>
      <li className="item product product-item ">
        <div className="product-item-info">
          <a
            href={`/${url_key}${url_prefix || ''}`}
            className="product-item-image"
          >
            <Image {...image} dimWidth={400} />
          </a>
          {discount > 0 && <span className="sale-label">{discount}%</span>}
          <div className="product-item-details">
            <a
              title={name}
              href={`/${url_key}${url_prefix || ''}`}
              className="product-item-name"
            >
              {name}
            </a>

            {!isGiftProduct && (
              <>
                {rating_summary ? (
                  <div className="rating-container">
                    <div className="RatingBadge">
                      <span>{(5 * (rating_summary / 100)).toFixed(0)}</span>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 6 7"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.00809 0.834882L3.70517 2.9803H5.961L4.136 4.30624L4.83309 6.45165L3.00809 5.12571L1.18309 6.45165L1.88017 4.30624L0.055174 2.9803H2.311L3.00809 0.834882Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <p className="rating-text">
                      {rating_summary} Rating & {review_count} Reviews
                    </p>
                  </div>
                ) : (
                  <div className="rating-container">
                    <div className="RatingBadge">
                      <span>{0}</span>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 6 7"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.00809 0.834882L3.70517 2.9803H5.961L4.136 4.30624L4.83309 6.45165L3.00809 5.12571L1.18309 6.45165L1.88017 4.30624L0.055174 2.9803H2.311L3.00809 0.834882Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <p className="rating-text">
                      {0} Rating & {0} Reviews
                    </p>
                  </div>
                )}
              </>
            )}

            <WishlistButton />
            {!isGiftProduct && (
              <div className="product-price">
                <ProductPrice />
              </div>
            )}
            {!isGiftProduct && (
              <div className="mrp-inclusive">MRP INCLUSIVE OF ALL TAXES.</div>
            )}
          </div>
          <div>
            {isGiftProduct && (
              <Link href={`/${url_key}${url_prefix || ''}`}>
                <Button variant={'secondary'}>SELECT AMOUNT</Button>
              </Link>
            )}
            <ConfigurableProductV2 />
            {isProductSimple({ __typename }) ? (
              <fieldset className="fieldset">
                <Form.Field label={null}>
                  <div className="control">
                    <div className="input">{pack_size}</div>
                  </div>
                </Form.Field>
              </fieldset>
            ) : null}
            {isProductBundle({ __typename }) ? (
              <fieldset className="fieldset">
                <Form.Field label={null}>
                  <div className="control">
                    <div className="input">{pack_size}</div>/
                  </div>
                </Form.Field>
              </fieldset>
            ) : null}
            {wishlist_id && (
              <div
                className="pointer"
                onClick={() => {
                  mutate({
                    wishlistId: wishlist_id,
                    wishlistItemsIds: [wishlist_item_id],
                  })
                }}
              >
                Remove from wishlist
              </div>
            )}
            <AddToCartButton />
          </div>
        </div>
        {isGrid && (
          <div
            className="grid-items-short-description"
            dangerouslySetInnerHTML={{ __html: short_description.html }}
          ></div>
        )}
      </li>
    </ProductContextProvider>
  )
}

export default ProductTile
