import useWindowSize from '@/hooks/useWindowSize'
import {
  createContext,
  useMemo,
  useContext,
  useState,
  useReducer,
  useEffect,
  useRef,
} from 'react'
import { Helmet } from 'react-helmet'

interface IAppState {}
interface IAppApi {}
type IAppContext = any[]
const initialAppState: IAppState = {}
const initialAppApi: IAppApi = {}

const AppContext = createContext<IAppContext>([{}, {}])
const preventDefault = (e) => {
  e && e[0] && e[0].preventDefault && e[0].preventDefault()
}
const AppContextProvider = (props) => {
  const {
    children,
    mitter,
    menu,
    storeConfig,
    content,
    blogCategories,
    content_temp,
  } = props
  // console.log('props',props);
  const [deviceType, setDeviceType] = useState(props.deviceType)

  useWindowSize(({ height, width }) => {
    // { vtablet: '769px', vdesktop: '1024px' }
    let newDeviceType = 'mobile'
    if (width > 1024) {
      newDeviceType = 'desktop'
    } else if (width > 769) {
      newDeviceType = 'tablet'
    }
    if (deviceType != newDeviceType) {
      // Refresh complete all for device type change
      setDeviceType(newDeviceType)
    }
  }, deviceType)

  const [modal, setModal] = useState<any>('')
  const onModal = (...args) => {
    const [modal] = args
    setModal(modal)
  }
  useEffect(() => {
    mitter && mitter.on('onModal', onModal)
    return () => {
      mitter && mitter.off('onModal', onModal)
    }
  }, [mitter])

  const appApi = useMemo(
    () => ({
      closeModals: () => {
        mitter.emit('onModal', null)
      },
      openLoginModal: (...e) => {
        preventDefault(e)
        mitter.emit('onModal', 'common.login', e?.slice(1))
        return false
      },
      openRegisterModal: (...e) => {
        preventDefault(e)
        mitter.emit('onModal', 'common.register', e?.slice(1))
        return false
      },
      openForgotPasswordModal: (...e) => {
        preventDefault(e)
        mitter.emit('onModal', 'common.forgotPassword', e?.slice(1))
        return false
      },
      openModal: (model, ...args) => {
        mitter.emit('onModal', ...args)
      },
    }),
    []
  )

  const moveRef = useRef(true)

  useEffect(() => {
    var activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      // 'scroll',
      'touchstart',
    ]
    function activity(e) {
      if (moveRef.current) {
        mitter.emit('firstActivity')
      }
      moveRef.current = false
    }
    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, activity, true)
    })
    return () => {
      activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, activity, true)
      })
    }
  }, [moveRef, mitter])

  const appState = {
    deviceType,
    mitter,
    isMobile: deviceType == 'mobile',
    isTablet: deviceType == 'tablet',
    isDesktop: deviceType == 'desktop',
    handleLoader: props.handleLoader,
    modal,
    menu,
    storeConfig,
    content,
    blogCategories,
    content_temp,
  }

  const contextValue: IAppContext = useMemo(
    () => [appState, appApi],
    [appState, appApi]
  )

  return (
    <AppContext.Provider value={contextValue}>
      <Helmet
        bodyAttributes={{
          class: deviceType,
        }}
      />

      {children}
    </AppContext.Provider>
  )
}

// export default connect(mapStateToProps, mapDispatchToProps)(AppContextProvider);
export default AppContextProvider

export const useAppContext = () => useContext(AppContext)
