import cart from "../queries/cart";

export const setShippingAddressesOnCart = `
mutation( $input: SetShippingAddressesOnCartInput ) {
  setShippingAddressesOnCart( input: $input){
    cart{
      ${cart({
  get_default: false,
  // applied_coupons: true,
  // filler_products: true,
  // items: true,
  prices: true,
  // available_payment_methods: full,
  // billing_address: full,
  // selected_payment_method: full,
  shipping_addresses: true,
})}
    }
  }
}
`;
export const setBillingAddressOnCart = `
mutation( $input: SetBillingAddressOnCartInput ) {
  setBillingAddressOnCart( input: $input){
    cart{
      ${cart({
  get_default: false,
  // applied_coupons: true,
  // filler_products: true,
  // items: true,
  // prices: true,
  available_payment_methods: true,
  billing_address: true,
  // selected_payment_method: full,
  // shipping_addresses: true,
})}
    }
  }
}
`;
export const setShippingMethodsOnCart = `
mutation( $input: SetShippingMethodsOnCartInput ) {
  setShippingMethodsOnCart( input: $input ){
    cart{
      ${cart({
  // applied_coupons: true,
  // filler_products: true,
  // items: true,
  prices: true,
  // available_payment_methods: full,
  // billing_address: full,
  // selected_payment_method: full,
  shipping_addresses: true,
})}
    }
  }
}
`;
export const setGuestEmailOnCart = `
mutation( $input: SetGuestEmailOnCartInput ) {
  setGuestEmailOnCart( input: $input ){
    cart{
      email
    }
  }
}
`;
export const setPaymentMethodOnCart = `
mutation( $input: SetPaymentMethodOnCartInput ) {
  setPaymentMethodOnCart( input: $input){
    cart{
      ${cart({
  // applied_coupons: true,
  // filler_products: true,
  // items: true,
  prices: true,
  // available_payment_methods: full,
  // billing_address: full,
  selected_payment_method: true,
})}
    }
  }
}
`;

export const placeOrderMutation = `
mutation ($input: PlaceOrderInput){
  placeOrder( input:$input ){
    order {
      order_number
      total
    }
  }
}
`;

export const placeRazorpayOrder = (cart_id) => `
  mutation {
    placeRazorpayOrder (
      cart_id: "${cart_id}"
    ){
      success
      rzp_order_id
      order_quote_id
      amount
      currency
      message
    }
  }
`;
export const setRzpPaymentDetailsOnCart = (
  cart_id,
  rzp_payment_id,
  rzp_order_id,
  rzp_signature
) => `
mutation {
  setRzpPaymentDetailsOnCart (
    input: {
      cart_id: "${cart_id}"
      rzp_payment_id: "${rzp_payment_id}"
      rzp_order_id: "${rzp_order_id}"
      rzp_signature: "${rzp_signature}"
    }
  ){
    cart{
      id
    }
  }
}
`;

export const triggerPendingOrder = `

mutation($quote_id : String ){
 triggerPendingOrder(quote_id: $quote_id ){
  message
  status
 }
}

`;

export const applyMwGiftCardToCart = `
mutation($input : ApplyMwGiftCardToCartInput){
  applyMwGiftCardToCart(input:$input){
    cart{
      ${cart({
  prices: true
})}
    }
  }
}
`
export const removeMwGiftCardFromCart = `
mutation($input : RemoveMwGiftCardToCartInput){
  removeMwGiftCardFromCart(input:$input){
    cart{
      ${cart({
        prices: true
      })}
    }
  }
}
`
export const reorderItems = `
  mutation ($orderNumber : String!){
    reorderItems (orderNumber : $orderNumber){
      cart{
        ${cart({
          prices:true,
          get_default: true,
          applied_coupons: true,
          available_payment_methods : true,
          billing_address : true,
          items : true,
          selected_payment_method : true,
          shipping_addresses : true,
          filler_products : true,
        })}
      }
    }
  }
`