import './cart-payment-tile.scss'
import { Currency } from '@/services/helpers/currency';
import useCartContext from '../useCartContext';
import { useEffect, useMemo } from 'react';
import { checkFreeShipping } from '../../Checkout/checkFreeShipping';

const ALLOWED_COUPONS = [
   'PTMAVG50',
   'PTMMFW50',
   'PTMAVG300',
   'PTMHS50',
   'PTMHS300',
   'PTMHFW',
   'PTMAMLA',
   'PTMAVG1',
   'PTMAVG50',
   'PTMAVG300',
]

export const CartPaymentTile = () => {
    const [cart, cartDispatch] = useCartContext()
    const {
        prices,
        extentionAttr,
        mstRewardPoints,
        applied_mw_gift_cards
    } = cart
    const totalDiscount = prices && prices.discounts && prices.discounts.reduce((initialCount, val) => {
        return val.amount.value + initialCount
    }, 0)
    let shippingCharge = prices.shipping_flat_rate
   let handlingCharge = 0
   let hasCouponApplied = (_couponCode: string) => {
      let filteredCoupons =  cart?.applied_coupons?.find(ci => _couponCode == ci.code?.toUpperCase())
      return filteredCoupons
   }

   const isPaytmOffer = useMemo(() => {
      if (cart?.items) {
         // console.log("Items", cart.items)
         // const applicableItems = cart.items?.filter(ci => ci.product.sku == 'MAVG')
         const hasCoupon = cart.applied_coupons?.filter(ci => ALLOWED_COUPONS.includes(ci.code?.toUpperCase()))
         console.log({ hasCoupon })
         if (hasCoupon?.length) {
            return {
               shippingCharge: 'FREE',
               handlingCharge: 94,
            }
         }
      }
      return null
   }, [ cart ])
   if (isPaytmOffer) {
      shippingCharge = isPaytmOffer.shippingCharge
    //   handlingCharge = isPaytmOffer.handlingCharge
   }
   let hasAvgCoupon = hasCouponApplied('PTMAVG1')
   if (hasAvgCoupon) {
      handlingCharge = 94
   }
    return (
        <>
            {prices && <div className={'cart-payment-wrapper'}>
                <div className={'wrapper-title'}>Payment Details</div>
                <div className={'payment-content'}>
                    <div className={'title'}>
                        <div>Total Value</div>
                        {prices && prices.discounts && <div>Discount</div>}
                        <div>Shipping Fee</div>
                        {((isPaytmOffer && handlingCharge) || hasAvgCoupon) && <div>Handling Fee</div>}
                        {mstRewardPoints.spend_points != 0 && <div>Glow Points</div>}
                        {applied_mw_gift_cards && <div>Gift Card</div>}
                    </div>
                    <div className={'value'}>
                        <div>{Currency({ money: prices && prices.subtotal_including_tax.value })}</div>
                        {hasAvgCoupon ? (
                        <>
                            {totalDiscount && <div aria-label='Discount' className='primary-color'>
                            {Currency({ money: 189 })}
                            </div>}
                        </>
                        ): (
                        <>
                        {totalDiscount && <div aria-label='Discount' className='primary-color'>
                        {Currency({ money: totalDiscount })}
                        </div>}
                        </>
                        )}
                        {(isPaytmOffer && shippingCharge) ? (
                        <div aria-label='Shipping Fee'>{'FREE'}</div>
                        ): (
                        <>
                        <div aria-label='Shipping Fee'>
                            {extentionAttr && extentionAttr.cart_shipping_method == 'false' ? `${Currency({ money: shippingCharge })}` : 'Free'}
                        </div>
                        </>
                        )}
                        {((isPaytmOffer && handlingCharge) || hasAvgCoupon) ? (
                        <div aria-label='Handling Fee'>{Currency({ money: handlingCharge })}</div>
                        ): null}
                        {mstRewardPoints.spend_points != 0 && <div className={'glow-point-reward'}>
                            <div className='primary-color'>
                                {Currency({ money: mstRewardPoints?.spend_points })}
                            </div>
                        </div>}
                        {applied_mw_gift_cards && <div className={'glow-point-reward'}>
                            <div className='primary-color'>
                                {applied_mw_gift_cards[0].applied}
                            </div>
                        </div>}
                    </div>

                </div>
                <div className={'total'}>
                    <div className={'title'}>
                        Total Amount
                    </div>
                    <div>
                        {Currency({ money: checkFreeShipping(extentionAttr, prices) })}
                    </div>
                </div>
                <small>
                    Prices are inclusive of all taxes
                </small>
            </div>}
        </>
    )
}

