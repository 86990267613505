import clsx from "clsx";
import styles from "./address.module.scss";

export const AddressTileBase = ({
  address,
  className = null,
  children = null,
}) => {
  return (
    <div className={clsx(styles.address_title_wrapper, className)}>
      <div className={styles.user_info}>
        <div className={styles.name}>
          {address?.firstname} {address?.lastname}
        </div>
        <div className={styles.address_line_1}>{address?.street.join(" ")}</div>
        <div className={styles.address_line_2}>
          {address?.city}, {address?.region_name || address?.region}, {address?.postcode}
        </div>
      </div>
      {children}
    </div>
  );
};

export const AddressTileLoader = (props) => {
  return (
    <div className={styles.address_title_wrapper}>
      <div className={styles.user_info}>
        <div className={"skeleton-box"}></div>
        <br />
        <div className={"skeleton-box"}></div>
        <br />
        <div className={"skeleton-box"}></div>
      </div>
    </div>
  );
};
