import { useAppContext } from "@/core/App/AppContext";
import clsx from "clsx";
import useCartContext from "../useCartContext";
import "./cart-header.scss";

export const CartHeader = () => {
  const [{ mitter }] = useAppContext();
  const [cartState] = useCartContext()
  const { items } = cartState;
  return (
    <div className="cart-header">
      <a
        className={"close pointer"}
        onClick={() => {
          mitter.emit("MiniCartToggle");
        }}
        href="#"
      />
      <span>{items.length == 0 ? "Empty Cart" : "My Cart"}</span>
    </div>
  );
};
