import "./accordian.scss";
import { useEffect, useState, memo, ReactNode } from "react";
import clsx from "clsx";

interface Accordian {
  title: string | ReactNode;
  children: any;
  selectedIndex: any;
  onClick: any;
  index: any;
  className?:any
}
export const Accordian = ({
  title,
  children,
  selectedIndex = null,
  onClick = null,
  index = null,
  className=null
}: Accordian) => {
  return (
    <>
      <div
        onClick={() => {
          if (selectedIndex == index) {
            // logic is used if selected index is same as current index
            if (
              document.getElementsByClassName("accordian-active").length == 0
            ) {
              document
                .getElementsByClassName("accordion")
                [selectedIndex].classList.add("accordian-active");
              document
                .getElementsByClassName("panel")
                [selectedIndex + 1].classList.add("panel-active");
            } else {
              document
                .getElementsByClassName("accordion")
                [selectedIndex].classList.remove("accordian-active");
              document
                .getElementsByClassName("panel")
                [selectedIndex + 1].classList.remove("panel-active");
            }
          }
          onClick(index);
        }}
        className={clsx(
          "accordion",
          className,
          selectedIndex == index && "accordian-active"
        )}
      >
        {title}
      </div>
      <div className={clsx("panel", selectedIndex == index && `panel-active ${className}-active`)}>
        {selectedIndex == index ? children : null}
      </div>
    </>
  );
};
