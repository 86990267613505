import Link from '@/core/Link'
import Button from '../../Button'
import Form from '../../Form'

import './footer-wrapper.scss'

export default function FooterWrapper(props) {
  return (
    <div className="FooterMainWrapper">
      <div className="footer-wrapper container-width desktopFooter">
        <div className="wrapper-column-1">
          <h2 className="column-title">Menu</h2>
          <div className=" footer-wrapper-list">
            <ul>
              <li className="page-redirect">
                <Link href="/faqs"> FAQ</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/customer-service"> Customer Service </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link
                  href="/cancellation-policy"
                  data-action="cancellation-policy"
                >
                  {' '}
                  Returns & Cancellation Policy
                </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/terms-and-conditions"> Terms & Conditions</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/privacy-policy"> Privacy Policy </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/contact-us"> Contact </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/moha-glow-points-terms-conditions">
                  {' '}
                  moha: Glow Points Terms & Conditions{' '}
                </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/csr"> moha: Adopt an NGO initiative </Link>
              </li>
            </ul>

            {/* <ul>
              <li className='page-redirect'>
                <Link href="/bigfan"> Salim's Music - The Biggest Fan Contest</Link>
              </li>
            </ul> */}
          </div>
          <div className="rOffice">
            <h2 className="column-title">Registered Office</h2>
            <p>
              21, Evergreen Industrial Estate, Shakti <br /> mills lane, Off Dr.
              E. Moses Road,
              <br /> Mahalaxmi, Mumbai-400011
            </p>
          </div>
          {/* <div className='column-3-button'>
          <a href="https://moha.shiprocket.co/" target="_blank">
              <img  className="center autowidth" src="https://moha.b-cdn.net/wp-content/uploads/2021/05/shiprocket.webp"
              title="Image" width="231"/>
          </a>
          </div> */}
        </div>
        {/* <div className='wrapper-figures'>
            <figure className='figure-1 moha-figure'>
              <img width="154" height="40" src="https://www.moha.co.in/media/uploads/Moha_Logo_natures_perfect.webp"
                className="vc_single_image-img attachment-medium" alt="Moha" loading="lazy"
                srcSet="https://www.moha.co.in/media/uploads/Moha_Logo_natures_perfect.webp 184w,
            https://moha.b-cdn.net/wp-content/uploads/2019/03/Moha_Logo_natures_perfect-64x17.webp 64w" sizes="(max-width: 184px) 100vw, 184px" />
            </figure>
            <figure className='figure-2 vedistry-figure'>
              <img src="https://www.moha.co.in/media/uploads/Vedistry_Logo.png" width={70}
                className="vc_single_image-img attachment-medium" alt="Vedistry" loading="lazy" srcSet="https://www.moha.co.in/media/uploads/Vedistry_Logo.png 300w,https://www.moha.co.in/media/uploads/Vedistry_Logo.png 64w,https://www.moha.co.in/media/uploads/Vedistry_Logo.png 448w" sizes="(max-width: 300px) 100vw, 300px"></img>
            </figure>
          </div> */}
        <div className="wrapper-column-2">
          <h2 className="column-title">Products</h2>
          <div className="footer-wrapper-list">
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/skin"> Skin Care Basics</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/bath-body">
                  {' '}
                  Bath and Body Basics{' '}
                </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/hair"> Hair Basics</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/spa"> Spa Products</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/offers"> Offers </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/gift-card"> Gift Card </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/corporate-gifting"> Corporate Gifting </Link>
              </li>
            </ul>
          </div>
          <div className="legal1">
            <p className="column-title">
              Country of Origin: <span> India </span>{' '}
            </p>
          </div>
          <div>
            <h2 className="column-title">Marketing Office</h2>
            <p>
              Vedistry Pvt. Ltd.,
              <br />
              501/A, Poonam Chambers, Dr. Annie Besant Road,
              <br />
              Worli, Mumbai-400 018
            </p>
          </div>
        </div>
        <div className="wrapper-column-3">
          <div className="wrapper-info">
            <div>
              {/* <div>
                  <p className='phone'>
                    <i className=" phone fa fa-phone"></i>
                    <a className="first-phone" href="tel:8657451582 ">
                      02266846734 <br /> (Monday – Friday : 9.00 AM to 6.00 PM) </a>
                  </p>
                </div> */}
              {/* <p> */}
              {/* <i className="envelope fa fa-envelope"></i> */}
              <a
                className="email-width"
                href="mailto:customercare@vedistry.com"
              >
                {' '}
                customercare@vedistry.com
              </a>
              <div style={{ marginTop: '2px' }} />
              <p className="phone">
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width={16}
                    height={16}
                    className="icon icon-sm icon--white w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                      clipRule="evenodd"
                    />
                  </svg> */}

                <a className="first-phone" href="tel:8657451582 ">
                  +91 8657451582{' '}
                </a>
              </p>
              <p className="chat-with">
                <span>Chat Online with us : 11 AM – 5 PM </span>
              </p>
            </div>
            {/* <div className='footer-social-link'>
                <h4 className=''>Follow Us:</h4>
                <ul>
                  <li className='facebook social_block'>
                    <a href='https://www.facebook.com/themohalife' target={"_blank"}>
                      <em className='fa fa-facebook'>&nbsp;</em>
                    </a>
                  </li>
                  <li className='instagram social_block'>
                    <a href='https://www.instagram.com/themohalife/' target={"_blank"}>
                      <em className='fa fa-instagram'>&nbsp;</em>
                    </a>
                  </li>
                  <li className='youtube social_block'>
                    <a href='https://www.youtube.com/channel/UCUnl-YC1vYwy5hA7xpvNkFg/featured' target={"_blank"}>
                      <em className='fa fa-youtube'>&nbsp;</em>
                    </a>
                  </li>
                  <li className='twitter social_block'>
                    <a href='https://www.twitter.com/themohalife' target={"_blank"}>
                      <em className='fa fa-twitter'>&nbsp;</em>
                    </a>
                  </li>
                </ul>
              </div> */}
          </div>
          <div>
            <h2 className="column-title">Manufacturer:</h2>
            <p>
              Charak Pharma Pvt Ltd, 21, Evergreen <br />
              Industrial Estate, 2nd Floor, <br />
              Opposite-Dr. E. Moses Road, Shakti <br />
              Mills Ln, Lower Parel, Mumbai, <br />
              Maharashtra 400011 <br />
              02266846734 <br />
              (Monday – Friday : 9.00AM to 6.00PM)
            </p>
          </div>
        </div>
      </div>

      {/* =============== */}
      <div className="footer-wrapper container-width mobileFooter">
        <div className="wrapper-column-1">
          <h2 className="column-title">Products</h2>
          <div className="footer-wrapper-list">
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/skin"> Skin Care Basics</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/bath-body">
                  {' '}
                  Bath and Body Basics{' '}
                </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/hair"> Hair Basics</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/shop/category/spa"> Spa Products</Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/offers"> Offers </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/gift-card"> Gift Card </Link>
              </li>
            </ul>
            <ul>
              <li className="page-redirect">
                <Link href="/corporate-gifting"> Corporate Gifting </Link>
              </li>
            </ul>
          </div>
          <div className="menu-list-inner">
            <h2 className="column-title">Menu</h2>
            <div className="footer-wrapper-list">
              <ul>
                <li className="page-redirect">
                  <Link href="/faqs"> FAQ</Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link href="/customer-service"> Customer Service </Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link
                    href="/cancellation-policy"
                    data-action="cancellation-policy"
                  >
                    {' '}
                    Returns & Cancellation Policy
                  </Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link href="/terms-and-conditions"> Terms & Conditions</Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link href="/privacy-policy"> Privacy Policy </Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link href="/contact-us"> Contact </Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link href="/moha-glow-points-terms-conditions">
                    {' '}
                    moha: Glow Points Terms & Conditions{' '}
                  </Link>
                </li>
              </ul>
              <ul>
                <li className="page-redirect">
                  <Link href="/csr"> moha: Adopt an NGO initiative </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="wrapper-column-2">
          <div>
            <h2 className="column-title">Marketing Office</h2>
            <p>
              Vedistry Pvt. Ltd.,
              <br />
              501/A, Poonam Chambers, Dr. Annie Besant Road,
              <br />
              Worli, Mumbai-400 018
            </p>
          </div>
          <div className="rOffice">
            <h2 className="column-title">Registered Office</h2>
            <p>
              21, Evergreen Industrial Estate, Shakti <br /> mills lane, Off Dr.
              E. Moses Road,
              <br /> Mahalaxmi, Mumbai-400011
            </p>
          </div>
          <div className="legal1">
            <p className="column-title">
              Country of Origin: <span> India </span>{' '}
            </p>
          </div>
          <div>
            <h2 className="column-title">Manufacturer:</h2>
            <p>
              Charak Pharma Pvt Ltd, 21, Evergreen <br />
              Industrial Estate, 2nd Floor, <br />
              Opposite-Dr. E. Moses Road, Shakti <br />
              Mills Ln, Lower Parel, Mumbai, <br />
              Maharashtra 400011 <br />
              02266846734 <br />
              (Monday – Friday : 9.00,AM to 6.00PM)
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
