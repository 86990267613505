import { useAppContext } from "@/core/App/AppContext";
import { useRouterContext } from "@/core/Router/RouterContext";
import useAuthContext from "../../Contexts/AuthContext";

export default function AccountLinks() {
  const [auth] = useAuthContext();
  const [{ mitter }] = useAppContext();
  const router = useRouterContext();
  return (
    <ul className='account-links'>
      {/* <li className='greet'>
        <span className='not-logged-in'>Default welcome msg!</span>
      </li> */}
      <li>
        <a
          onClick={(e) => {
            auth.isAuthenticated
              ? router.push("/my-account/profile", {})
              : mitter.emit("TOGGLE_LOGIN_MODEL");

              mitter.emit('TOGGLE_NAV', e);
          }}
          
        >
          Log In{' '}
        </a>
      </li>
      <li>
        <a
          onClick={(e) => {
            auth.isAuthenticated
              ? router.push("/my-account/orders", {})
              : mitter.emit("TOGGLE_LOGIN_MODEL");

              mitter.emit('TOGGLE_NAV', e);
          }}
          
        >
          My Orders{' '}
        </a>
      </li>
      <li>
        <a
          onClick={(e) => {
            auth.isAuthenticated
              ? router.push("/my-account/wishlist", {})
              : mitter.emit("TOGGLE_LOGIN_MODEL");

              mitter.emit('TOGGLE_NAV', e);
          }}
          
        >
          My WishList{' '}
        </a>
      </li>
      {/* <li>
        <a href='http://magento2.templatemela.com/MAG2013/MAG110247_1/customer/account/create/'>
          Create an Account
        </a>
      </li> */}
      {/* <li>
        <a title='Compare Products'>
          Compare Products{' '}
          <span
            className='counter qty'
            data-bind='text: compareProducts().countCaption'
          ></span>
        </a>
      </li> */}
    </ul>
  );
}
