import { useAppContext } from '@/core/App/AppContext'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import useProductContext from '@/theme/route/ProductPage/useProductContext'
import { useState } from 'react'
import { notifyOutofStock } from 'src/graphql/mutations/customermutation'
import Button from '../Button'
import { ButtonLoader } from '../ButtonLoader'
import useCartContext from '../CartWrapper/useCartContext'
import useAuthContext from '../Contexts/AuthContext'
import { removeCartId } from '@/utils/cart'

export default function AddToCartButton(props) {
  const [{ mitter }] = useAppContext()
  const [, , { addToCart }] = useCartContext()
  const [, , { isSignedIn }] = useAuthContext()

  const [product, { getCartItem }] = useProductContext()
  const { id, __typename, stock_status } = product
  const [loading, setLoading] = useState(false)

  if (__typename == 'MageWorxGiftCards') {
    return null
  }
  if (stock_status != 'IN_STOCK') {
    return (
      <Button
        className="tocart"
        onClick={() => {
          isSignedIn().then((signedIn) => {
            if (!signedIn) {
              mitter.emit(
                'TOGGLE_LOGIN_MODEL',
                'Signin is required for notify stock'
              )
            } else {
              graphqlWebClient
                .request(notifyOutofStock(id))
                .then(({ NotifyOutofStock: { message, status } }) => {
                  if (status) {
                    alert(message)
                  }
                })
                .catch((err) => {
                  // alert('Something went wrong')
                })
            }
          })
        }}
      >
        Notify Me
      </Button>
    )
  }
  // const [cartState, cartDispatch, { removeItem, updateCartItem }] =
  //   useCartContext()
  // const { items } = cartState;
  // const cartItem = getCartItem()
  // console.log(items,'ffff')
  // items.map((item,i)=>(
  //   <>
  //   {item.product.sku == cartItem.sku ?
  //   <>
  //   {item.quantity + cartItem.quantity > 10 ?  }
  //   </>: null }
  //   </>
  // ))
  return (
    <Button
      onClick={() => {
        const cartItem = getCartItem()
        setLoading(true)
        addToCart(cartItem, product)
          .then((d) => {
            console.log({ cartItem, product })
            if (product.price == 0 && cartItem.quantity > 1) {
              removeCartId()
              window?.localStorage?.clear()
              // clear cart
              // throw
            }
            if (d.error) {
              throw 'The requested qty exceeds the maximum qty allowed in shopping cart'
              // throw new Error('The requested qty exceeds the maximum qty allowed in shopping cart')
            }
            mitter.emit('ShowToaster', { message: `Product Added to cart` })
            if (!sessionStorage.getItem(`${cartItem.sku}-upsell`)) {
              sessionStorage.setItem(`${cartItem.sku}-upsell`, '1')
              props.isUpsellModal && mitter.emit('UpsellModal', cartItem.sku)
            }
          })
          .catch((e) => {
            console.log(e, 'e')
            mitter.emit('ShowToaster', { message: e })
          })
          .finally(() => {
            setLoading(false)
          })
      }}
      className="tocart btn--add-to-cart"
      variant="secondary"
    >
      {loading ? <ButtonLoader /> : 'ADD TO CART'}
    </Button>
  )
}
