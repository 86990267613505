import { useAppContext } from "@/core/App/AppContext";
import Link from "@/core/Link";
import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import { getCartId } from "@/utils/cart";
import { useForm } from "react-hook-form";
import { applyMwGiftCardToCart, removeMwGiftCardFromCart } from "src/graphql/mutations/checkoutmutation";
import { mstRewardsApplyPointsToCart } from "src/graphql/mutations/customermutation";
import { aPIEvent, getError } from "src/services/ga";
import Button, { Buttons } from "../../Button";
import useCartContext from "../../CartWrapper/useCartContext";
import useAuthContext from "../../Contexts/AuthContext";
import Form from "../../Form";
import "./checkout-gift-card.scss";

export const CheckoutGiftCard = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [{ mitter }] = useAppContext()
  const [cart, cartDispatch] = useCartContext();
  const { applied_mw_gift_cards } = cart
  const cart_id = getCartId();

  const removeGiftCard = async () => {
    const { removeMwGiftCardFromCart: removeGiftCard } = await graphqlWebClient.request(removeMwGiftCardFromCart, {
      input: {
        cart_id: cart_id,
        gift_card_code: applied_mw_gift_cards[0].code
      }
    });
    const {
      cart
    } = removeGiftCard;
    cartDispatch({
      type: "addProductToCart",
      payload: {
        applied_mw_gift_cards: cart,
        ...cart
      },
    });
    if (!cart.applied_mw_gift_cards) {
      mitter.emit("ShowToaster", {
        message: "Gift Card Removed",
      });
    }
  }
  const onSubmit = async ({ giftcard }) => {
    let date = new Date();
    try {
      const setGiftCard = await graphqlWebClient.request(applyMwGiftCardToCart, {
        input: {
          cart_id: cart_id,
          gift_card_code: giftcard
        }
      });
      const {
        applyMwGiftCardToCart: { cart },
      } = setGiftCard;
      cartDispatch({
        type: "addProductToCart",
        payload: {
          applied_mw_gift_cards: cart,
          ...cart
        },
      });
      aPIEvent('Gift Card Applied to Cart','success',date)
      if (cart.applied_mw_gift_cards) {
        mitter.emit("ShowToaster", {
          message: "Gift Card Applied",
        });
      }
    } catch (err) {
      console.log(err);
      mitter.emit("ShowToaster", {
        message: "Invalid Gift Card",
      });
      aPIEvent('Gift Card Applied to Cart',getError(err),date)
      // alert("Something went wrong");
    }
  };

  return (
    <>
      {!applied_mw_gift_cards ? <div>
        <div className="gift-card-text">
          Redeem and use your Gift Card now.
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='gift-card-input fieldset'>
            <Form.TextField
              name="giftcard"
              label="Enter Gift Card Code"
              {...register("giftcard")}
            ></Form.TextField>
            <div className="submit-button">
              <Buttons>
                <Button>Apply</Button>
              </Buttons>

            </div>
          </div>
        </form>
      </div>
        : <>
          <div className="gift-card-applied-text">
            <b>{applied_mw_gift_cards[0].applied}</b> applied from Gift Card <b>{applied_mw_gift_cards[0].code}.</b>
            <br />
            Remaining card balance: {<b>{applied_mw_gift_cards[0].remaining}</b>}
            <br />
            <div className='pointer primary-color'
              onClick={removeGiftCard}
            >(Remove)</div>
          </div>
        </>}
    </>
  );
};
