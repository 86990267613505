import './product-slider.scss'
import ProductTile, { ProductTileLoader } from '@/theme/components/ProductTile'
// import MainSlider, { SwiperSlide } from '@/theme/components/MainSlider'
import { TitleTile } from '@/theme/components/TitleTile'
import { useAppContext } from '@/core/App/AppContext'
import { getProductDefaultCartItem } from 'src/services/product.query.modifier'

const ProductSlider = ({
  title,
  items = [],
  slides = null,
  loading = false,
}) => {
  const [{ isMobile }] = useAppContext()
  const slidesPerView = isMobile ? 2 : slides ? slides : 4
  return (
    <div className="product-list-slider">
      <TitleTile title={title} />
      <div className="product-list-items">
        {items.map((item) => (
          <ProductTile {...getProductDefaultCartItem(item)} key={item.uid} />
        ))}
      </div>
      {/* {(title == 'Our Best Sellers' && isMobile ) ?
        
        <>
        {loading
        ? new Array(slidesPerView).fill(0).map((a, i) => (
            <SwiperSlide key={i}>
              <ProductTileLoader />
             </SwiperSlide>
          ))
        : 
        <div className="swiper-container best-sellers-section">
        {items.map((data, key) => (
            <SwiperSlide key={data.sku} style={{flex:"0 0 46%"}}>
              <ProductTile {...getProductDefaultCartItem(data)} />
              </SwiperSlide>
            ))}
            </div>  
        }
        </>
        
      :
      <MainSlider
        spaceBetween={30}
        slidesPerView={slidesPerView}
        loading={loading}
      >
        {loading
          ? new Array(slidesPerView).fill(0).map((a, i) => (
              <SwiperSlide key={i}>
                <ProductTileLoader />
              </SwiperSlide>
            ))
          : items.map((data, key) => (
              <SwiperSlide key={data.sku}>
                <ProductTile {...getProductDefaultCartItem(data)} />
              </SwiperSlide>
            ))}
      </MainSlider>
       }  */}
    </div>
  )
}

export default ProductSlider
