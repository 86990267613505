import './checkout-shipping.scss'
import Form from '@/theme/components/Form'
import Button, { Buttons } from '@/theme/components/Button'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import useCartContext from '../../CartWrapper/useCartContext'
import { Currency } from '@/services/helpers/currency'
import { placeOrderFlow, setAddressOnCart } from 'src/services/checkoutService'
import { useEffect, useMemo, useState } from 'react'
import { graphqlGuestClient, graphqlWebClient } from '@/theme/lib/graphqlClient'
import { getStateQuery } from 'src/graphql/queries/getStateQuery'
import { Helmet } from 'react-helmet'
import { checkFreeShipping } from '../checkFreeShipping'
import useAuthContext from '../../Contexts/AuthContext'
import { AddressTile } from './AddressTile'
import PersistanceStorage from '@/utils/storage'
import { removeCartId } from '@/utils/cart'
import { useRouterContext } from '@/core/Router/RouterContext'
import { AddNewAddress } from '../../AccountWrapper/AddressTile/AddNewAddress'
import { useAppContext } from '@/core/App/AppContext'
import { fBPurchase } from 'src/services/fbEvents'
import { aPIEvent, getError } from 'src/services/ga'
import { checkoutEvent, purchaseEvent } from 'src/services/gtm'
import ModalComponent from '../../ModalComponent'
import we from 'src/services/webengage'
import ReCaptcha from 'react-google-recaptcha'
import { GCAPTCH_SITE_KEY } from 'src/config'
import { sendOTPAsync } from '../OTPVerificationWidget'
import { setPaymentMethodOnCart } from 'src/graphql/mutations/checkoutmutation'

const applyPayNowDiscount = async (cart) => {
  let ok = false
  return {
    ok,
  }
}

const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export function dateFormat(d: Date) {
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return (
    d.getDate().toString() +
    ' ' +
    month[d.getMonth()] +
    ' ' +
    d.getFullYear().toString()
  )
}

declare var fbq

export const ShippingForm = (props) => {
  const { disableEmail = false } = props
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()
  const getState = async (data) => {
    const { getState } = await graphqlGuestClient.request(getStateQuery(data))
    if (getState.city != null) {
      // onPincodeChange(getState)
      setValue('region', getState.state_code)
      setValue('region_name', getState.state)
      setValue('region_id', getState.region_id)
      setValue('city', getState.city)
    } else {
      alert('Pincode is not valid')
    }
  }

  return (
    <div className="shipping-input fieldset">
      <Form.TextField
        {...register('postcode', {
          pattern: {
            value: /\d{6}/,
            message: 'Invalid pincode',
          },
          minLength: {
            value: 6,
            message: 'Please enter 6 digit pin code',
          },
          maxLength: {
            value: 6,
            message: 'Please enter 6 digit pin code',
          },
          required: {
            value: true,
            message: 'This is a required field',
          },
        })}
        error={errors.postcode?.message}
        placeholder="Pincode"
        className="pincode"
        onInput={(e) => {
          if (e.currentTarget.value.length == 6) {
            getState(e.currentTarget.value)
          }
        }}
      />
      <Form.TextField
        placeholder="Flat No./Building Name"
        className="address"
        {...register('street.0', {
          required: 'This field is required',
        })}
        error={
          errors?.street && errors?.street[0] && errors?.street[0]?.message
        }
      />
      <Form.TextField
        style={{
          padding: '1rem !important',
        }}
        placeholder="Road, Lane, Area, City"
        className="address"
        {...register('street.1', {
          required: 'This field is required',
        })}
        error={
          errors?.street && errors?.street[1] && errors?.street[1]?.message
        }
      />
      <h3 style={{ marginLeft: '1rem', marginTop: '8px' }}>Contact</h3>

      <Form.TextField
        placeholder="First Name"
        {...register('firstname', {
          required: 'Firstname is required',
        })}
        error={errors.firstname?.message}
      />
      <Form.TextField
        name="lastname"
        placeholder="Last Name"
        {...register('lastname', {
          required: 'Lastname is required',
        })}
        error={errors.lastname?.message}
      />
      <Form.TextField
        name="telephone"
        type="number"
        placeholder="Phone"
        {...register('telephone', {
          required: 'Mobile number is required',
          pattern: {
            value: /^\d{10}$/,
            message: 'Mobile number must contain 10 digits',
          },
        })}
        error={errors.telephone?.message}
      />
      {!disableEmail && (
        <Form.TextField
          name="email"
          placeholder="Email Id"
          {...register('email', {
            required: 'Email Id is required',
            pattern: {
              value: EmailRegex,
              message: 'Email Id is required',
            },
          })}
          error={errors.email?.message}
        />
      )}

      <Form.TextField
        {...register('street.2')}
        placeholder={'Landmark'}
        // label="Landmark"
        className="landmark"
      />
      <Form.TextField
        placeholder="City"
        className="city"
        readOnly
        {...register('city', { required: 'This field is required' })}
      />
      <input type="hidden" {...register('region')} />
      <input type="hidden" {...register('region_id')} />
      <Form.TextField
        placeholder="State"
        className="state"
        readOnly
        {...register('region_name', { required: 'This field is required' })}
      />
      <input type="hidden" {...register('country_code')} value="IN" />
    </div>
  )
}

const PaymentMethods = (props) => {
  const { extentionAttr, prices, code, isCaptchaVerified = false } = props
  const { register, setValue, getValues } = useFormContext()
  const [open, openModal] = useState(false)
  const [openLimitModal, setopenLimitModal] = useState(false)
  const [
    cart,
    _,
    { applyCoupon, removeCoupon, setPaymentMethod, setShippingAddress },
  ] = useCartContext()
  {
    /* for cod we are adding 25 INR on subtotal */
  }
  const codRate =
    prices.grand_total.value != 0 &&
    Currency({
      money:
        checkFreeShipping(extentionAttr, prices) +
        (prices.cod_charges ? prices.cod_charges : 0),
    })
  const onlineRate =
    prices && Currency({ money: checkFreeShipping(extentionAttr, prices) })

  let cod_rate = prices.grand_total.value + prices.cod_charges
  let online_rate = prices.grand_total.value

  const [isDisabled, setIsDisabled] = useState(true)
  const [checked, setChecked] = useState(false)
  const [checkboxOne, setCheckboxOne] = useState(true)
  const canBeSubmitted = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false)
  }
  const MAX_COD_ORDER_AMOUNT = 15000
  console.log({ code, online_rate })
  let canPlaceCODOrder = false
  if (online_rate <= MAX_COD_ORDER_AMOUNT) {
    canPlaceCODOrder = true
  }

  const onCheckboxClick = () => {
    setChecked(!checked)
    return canBeSubmitted()
  }

  const set_payment_method = async () =>
    await graphqlWebClient.request(
      // setting payment method as per user select
      setPaymentMethodOnCart,
      {
        input: { cart_id: props.cart_id, payment_method: { code: 'razorpay' } },
      }
    )

  const onCODClick = async (e) => {
    const promotPayNowMgs = 'Use Pay Online Option to avail 2% extra discount.'
    if (confirm(promotPayNowMgs)) {
      if (!props.isFormValid) {
        alert('The shipping address is missing. Set the address and try again.')
        return false
      }
      let {
        firstname,
        lastname,
        company,
        street,
        city,
        region,
        postcode,
        country_code,
        telephone,
        save_in_address_book = true,
      } = getValues()
      console.log('address', getValues())
      await setShippingAddress({
        address: {
          firstname,
          lastname,
          company,
          street,
          city,
          region,
          postcode,
          country_code,
          telephone,
          save_in_address_book: true,
        },
      })
      setValue('paymentMethod', 'razorpay')
      // applyPayNowDiscount(props)
      // await setPaymentMethod('razorpay')
      applyCoupon('PAYNOW')
      return false
    }
    removeCoupon()
    if (cod_rate <= 15000) {
      // e.currentTarget.disabled = true;
      // return false;
      setValue('paymentMethod', 'cashondelivery')
    } else {
      e.preventDefault()
      setopenLimitModal(true)
    }
  }

  return (
    <div>
      <div className="checkbox">
        <div className="checkbox1">
          <input
            type="checkbox"
            checked={checkboxOne}
            onClick={() => setCheckboxOne(!checkboxOne)}
          />
          <span>
            {' '}
            Keep me updated on my order status over email and WhatsApp
            (optional).
          </span>
        </div>
        <div className="privacypolicy-text">
          <p>
            Your personal data will be used to process your order, support your
            experience throughout this website, and for other purposes described
            in our <a href="/privacy-policy">privacy policy</a>.
          </p>
        </div>
        <div className="checkbox2">
          <input type="checkbox" onClick={onCheckboxClick} />
          <span className="box2-text">
            I have read and agree to website&nbsp;
            <a href="/terms-and-conditions">Terms & Conditions.</a>
            <span className="box2-text2">
              We will attempt to deliver your order at the earliest within 3 - 4
              days in the metro cities. For remote areas the delivery may be
              delayed.
            </span>
          </span>
        </div>
      </div>
      <Buttons className="payment-method">
        <input type="hidden" {...register('paymentMethod')} />
        {codRate ? (
          <>
            <Button
              onClick={onCODClick}
              disabled={props.loading || !props.isCodAllowed || isDisabled}
            >
              Cash on Delivery {prices && `(${codRate})`}
            </Button>
          </>
        ) : (
          <Button
            onClick={(e) => {
              setValue('paymentMethod', 'free')
            }}
            disabled={props.loading || !props.isCodAllowed || isDisabled}
          >
            Free Checkout
          </Button>
        )}
        <Button
          onClick={(e) => {
            if (online_rate <= 15000) {
              setValue('paymentMethod', 'razorpay')
            } else {
              e.preventDefault()
              setopenLimitModal(true)
            }
          }}
          disabled={props.loading || isDisabled || !codRate}
        >
          Pay Online {prices && `(${onlineRate})`}
        </Button>
      </Buttons>
      <ModalComponent
        isOpen={openLimitModal}
        close={(data) => setopenLimitModal(data)}
        title={''}
      >
        <div>COD order limit is Rs.15000/-</div>
      </ModalComponent>
    </div>
  )
}

const toCartAddress = (address) => {
  if (!address) return null

  if (address.region) {
    var region = address.region.code || address.region.region_code
    var region_name = address.region.region || address.region.label
    var region_id = address.region.region_id
  }
  if (address.country) {
    var country_code = address.country.code
  }
  return {
    ...address,
    country_code,
    region,
    region_name,
    region_id,
  }
}

export const CheckoutShipping = () => {
  const [auth] = useAuthContext()
  const [
    {
      mitter,
      storeConfig: { razorpay_key_id },
    },
  ] = useAppContext()
  const [
    cart,
    ,
    { getQuoteId, reloadCart, loadingCart, isCartStockStatusEmpty, resetCart },
  ] = useCartContext()

  const router = useRouterContext()
  const {
    extentionAttr,
    prices,
    shipping_addresses,
    billing_address,
    email: emailFromCart,
    items,
    available_payment_methods,
    hasOnlyGiftCard,
  } = cart
  const { isAuthenticated, email: emailFromAuth } = auth
  const [loading, setLoading] = useState(false)
  const [changeAdd, setChangeAdd] = useState(false)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const addresses = auth.addresses || []
  const email = isAuthenticated ? emailFromAuth : emailFromCart

  const shipping_address = toCartAddress(
    shipping_addresses && shipping_addresses[0]
  )

  const addressSelected = useMemo(() => {
    if (!isAuthenticated) return shipping_address // For guest return address set on cart

    // for logged in user show addess set on cart
    if (shipping_address?.region) {
      return shipping_address
    }
    // return default shipping address for customer
    return addresses.find((adr) => adr.default_shipping)
  }, [isAuthenticated, shipping_address, addresses])

  const hasAddressSetOnCart = useMemo(() => {
    if (hasOnlyGiftCard) return Boolean(billing_address?.postcode)
    return (
      Boolean(shipping_address?.postcode) && Boolean(billing_address?.postcode)
    )
  }, [hasOnlyGiftCard, shipping_address, billing_address])

  const methods = useForm({
    defaultValues: { ...shipping_address, email },
  })
  const setPageCallBack = () => {
    if (!isAuthenticated) return 'address_guest'
    if (addresses.length == 0) return 'address_new'
    if (addressSelected?.region) return 'address_selected'
    else if (addresses.length > 0) return 'address_list'
    return 'address_new'
  }

  const [pageState, setPageState] = useState(setPageCallBack)

  const isCodAllowed = useMemo(() => {
    return Boolean(
      items.find((item) => item.__typename != 'MageWorxGiftCardsCartItem')
    )
  }, [items.length])

  useEffect(() => {
    checkoutEvent(cart)
    const discountValue =
      cart?.prices?.discounts?.length > 0
        ? cart?.prices?.discounts.reduce((res, i) => res + i.amount.value)
        : 0
    we.event('Checkout Started', {
      'Product ID': `${cart.items.map((i) => i?.product?.uid)}`,
      'Product Id': `${cart.items.map((i) => i?.product?.sku)}`,
      'Product Name': `${cart.items.map((i) => i?.product?.name)}`,
      'Category Id': ``,
      'Category Name': '',
      'SubCategory Name': '',
      'SubCategory Id': '',
      'No Of Products': cart.total_quantity,
      'Total Amount': cart?.prices?.grand_total?.value,
      'Product Details': JSON.stringify(cart.items),
      'Discount Amount': discountValue?.amount?.value,
      'Coupon Code': `${
        cart?.applied_coupons?.length > 0
          ? cart?.applied_coupons.map((i) => i.code)
          : null
      }`,
      'Sub Total':
        cart?.prices?.grand_total?.value + discountValue?.amount?.value,
    })
    let checkProduct = cart.items.find((i) =>
      ['MOHSS-Sunscreen Spray', 'MOHSS'].includes(i?.product?.sku)
    )
    console.log('ChekProduct : ', checkProduct)
    if (checkProduct) {
      localStorage.setItem('run_thank_you_gtag', '1')
    }
  }, [])

  // console.log(address_guest,address_list,address_new,selected_address)
  const onSubmit = async (data) => {
    if (!isAuthenticated) {
      we.userLogin(
        {
          email: `${data?.email}`,
          phone: `${data?.telephone}`,
          first_name: `${data?.firstname}`,
          last_name: `${data?.lastname}`,
          user_id: `${data?.email}`,
          mode: `signup`,
          modeSource: {
            source: `Guest`,
            page: ``,
          },
        },
        'Guest'
      )
      we.setUserAttribute('we_whatsapp_opt_in', true)
      // OTP Verification starts here
      if (data?.telephone && data.paymentMethod == 'cashondelivery') {
        try {
          const otpverified = await sendOTPAsync(data?.telephone)
          console.log({ otpverified })
        } catch (error) {
          alert('OTP verification failed')
          return false
        }
      }
      // OTP Verification ends here
    }
    if (isCartStockStatusEmpty(items)) {
      alert(
        'Seems your cart consist of out of stock product. Kindly Remove and proceed.'
      )
      return
    }
    if (cart.items.length == 0) {
      return
    }
    setLoading(true)

    if (extentionAttr.cart_shipping_method == 'false') {
      data.shippingMethod = [
        {
          carrier_code: 'flatrate',
          method_code: 'flatrate',
        },
      ]
    } else {
      data.shippingMethod = [
        {
          carrier_code: 'freeshipping',
          method_code: 'freeshipping',
        },
      ]
    }

    const cartId = await getQuoteId()

    if (
      !hasAddressSetOnCart ||
      ['address_guest', 'address_new'].includes(pageState)
    ) {
      const cartUpdate = await setAddressOnCart(data, cartId)
      reloadCart(cartUpdate)
    }

    mitter.emit('NavigationStart', true)
    let date = new Date()
    placeOrderFlow(data, isAuthenticated, cartId, razorpay_key_id)
      .then(({ order, cartData }) => {
        setLoading(false)
        if (order.order_number) {
          cartData = {
            ...cartData,
            ...cart,
          }
          purchaseEvent(cartData, order.order_number)
          // fBPurchase(cartData)
          const storage = new PersistanceStorage()

          storage.setItem('order_number', order.order_number)
          removeCartId()
          resetCart()
          aPIEvent('Place Order', 'success', date)
          try {
            const formattedDate = dateFormat(new Date())
            const discountValue =
              cart?.prices?.discounts?.length > 0
                ? cart?.prices?.discounts.reduce(
                    (res, i) => res + i.amount.value
                  )
                : 0
            const TaxValue =
              cart?.prices?.applied_taxes?.length > 0
                ? cart?.prices?.applied_taxes.reduce(
                    (res, i) => res + i.amount.value
                  )
                : 0
            const checkoutCompleteEventPayload = {
              'Order Number': order?.order_number,
              'Order Id': `${cart?.id}`,
              'Order Date': `${formattedDate}`,
              'Payment Mode': `${data?.paymentMethod}`,
              'Product Id': `${cart.items?.map((i) => i?.product?.sku)}`,
              'Product ID': `${cart.items?.map((i) => i?.product?.uid)}`,
              'Product Name': `${cart.items?.map((i) => i?.product?.name)}`,
              'Category Id': ``,
              'Category Name': '',
              'SubCategory Name': '',
              'SubCategory Id': '',
              'No Of Products': cart?.total_quantity,
              'Total Amount': cart?.prices?.grand_total?.value,
              'Product Details': cart?.items,
              'Discount Amount': discountValue?.amount?.value || 0,
              'Coupon Code': `${
                cart?.applied_coupons?.length > 0
                  ? cart?.applied_coupons.map((i) => i.code)
                  : null
              }`,
              'Sub Total':
                cart?.prices?.grand_total?.value +
                  discountValue?.amount?.value ||
                cart?.prices?.grand_total?.value,
              'Shipping Charges': cart?.prices?.shipping_flat_rate || 0,
              'Tax Charged': TaxValue?.amount?.value || 0,
            }
            we.event('Checkout Completed', checkoutCompleteEventPayload)
            let checkoutCompleteEvent = {
              email: `${data?.email}`,
              phone: `${data?.telephone}`,
              first_name: `${data?.firstname}`,
              last_name: `${data?.lastname}`,
              order_number: order?.order_number,
              // user_id: `${data?.email}`,
            }
            localStorage.setItem(
              'checkoutCompleteEvent',
              JSON.stringify(checkoutCompleteEvent)
            )
          } catch (e) {}
          router.push(`/checkout/order-received`, {})
        }
      })
      .catch((e) => {
        setLoading(false)
        mitter.emit('NavigationEnd', false)
        aPIEvent('Place Order', getError(e), date)
        alert('Payment Process Declined. Please try again')
        we.event('  Payment Failed', {
          'Total Amount': cart?.prices?.grand_total?.value,
          'Payment Mode': `${data?.paymentMethod}`,
          Error: `${e}`,
        })
      })
      .finally(() => {
        mitter.emit('NavigationEnd', false)
      })
  }

  // useEffectAfterMount(() => {
  //   if (pageState == 'address_new') {
  //     if (addresses.length == 0) {
  //       methods.reset()
  //     }
  //     if (addresses.length > 0) {
  //       methods.reset({});
  //     }
  //   }
  // }, [pageState, addresses]);

  return (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
        <script src="https://control.msg91.com/app/assets/otp-provider/otp-provider.js"></script>
      </Helmet>
      <div className="checkout-shipping">
        <h3>Address</h3>
        {pageState == 'address_list' ? (
          <div className="address-book">
            {addresses &&
              addresses.map((data) => (
                <AddressTile
                  change={false}
                  select={true}
                  address={toCartAddress(data)}
                  clickOnSelect={(data) => {
                    setLoading(true)

                    return getQuoteId()
                      .then((quoteId) => {
                        return setAddressOnCart(data, quoteId)
                      })
                      .then((cart) => {
                        reloadCart(cart)
                        setLoading(false)
                        setPageState('address_selected')
                      })
                      .catch((e) => {
                        // loadingCart(false);
                        setLoading(false)
                      })
                  }}
                />
              ))}
            <AddNewAddress></AddNewAddress>
          </div>
        ) : null}

        {pageState == 'address_selected' ? (
          <AddressTile
            address={addressSelected}
            clickOnChange={() => {
              addresses.length < 2
                ? setPageState('address_new')
                : setPageState('address_list')
            }}
          />
        ) : null}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {['address_guest', 'address_new'].includes(pageState) && (
              <ShippingForm />
            )}

            {/* <ReCaptcha
              sitekey={GCAPTCH_SITE_KEY}
              onErrored={(event) => {
                console.log({ event })
                setIsCaptchaVerified(false)
              }}
              onExpired={(event) => {
                console.log({ event })
                setIsCaptchaVerified(false)
              }}
              onChange={(event) => {
                console.log({ event })
                setIsCaptchaVerified(true)
              }}
            />
            <br /> */}

            <PaymentMethods
              loading={loading}
              isCodAllowed={isCodAllowed}
              extentionAttr={extentionAttr}
              prices={prices}
              isFormValid={methods.formState.isValid}
              isCaptchaVerified={!isCaptchaVerified}
            />
          </form>
        </FormProvider>
        {/* <CheckoutMessageTile type="small">
          <div>Get 5% Extra Discount upto Rs. 500 by Paying Online</div>
        </CheckoutMessageTile> */}
      </div>
    </>
  )
}
