import "./header.scss";
import React, { useState, useEffect } from "react";

import CartWrapper from "@/theme/components/CartWrapper";
import HeaderBottom from "@/theme/components/Header/HeaderBottom";
import HeaderTop from "@/theme/components/Header/HeaderTop";
import { useAppContext } from "@/core/App/AppContext";
import { WhatsappIcon } from "@/theme/components/WhatsappIcon";

export const OfferText = ({ name = '', marquee = false, children }) => {
  const [{ content: offer_text }] = useAppContext();
  if (!offer_text) {
    return null;
  }
  const app = useAppContext()
  if (children) {
    return (
      <div className="offer-text">
        {children}
      </div>
    )
  }
  return (
    <>
      <div className="offer-text">
        {marquee ? <marquee> <div
          dangerouslySetInnerHTML={{ __html: offer_text.content }}
        ></div> </marquee> : <div
          dangerouslySetInnerHTML={{ __html: offer_text.content }}
        ></div>}
      </div>
    </>
  );
};
export const OfferTextTemp = ({ marquee = false }) => { // temporary component 
  const [{ content_temp: offer_text_temp }] = useAppContext();
  if (!offer_text_temp) return null

  return (
    <>
      <div className="offer-text offer-text-temp">
        <div
          dangerouslySetInnerHTML={{ __html: offer_text_temp.content }}
        ></div>
      </div>
    </>
  );
};

const Header = (props) => {
  const app = useAppContext()

  return (
    <header className="header-content">
      <OfferText marquee={true} />
      {/* <OfferTextTemp /> */}
      <div className="container-width">
        {/* <div className="block-search" id="block-search" /> */}
        <HeaderTop />
        <CartWrapper />
      </div>

      <WhatsappIcon />
    </header>
  );
};

export default Header;
