import { AddressTileBase } from "@/theme/components/AccountWrapper/AddressTile/AddressTileBase";
import { useState } from "react";

export const AddressTile = (props) => {
  const {
    address,
    change = true,
    select = false,
    clickOnChange,
    clickOnSelect,
  } = props;
  const [loading, setLoading] = useState(false);

  return (
    <AddressTileBase address={address} className="checkout-tile">
      {change ? (
        <div
          // className={styles.edit_operation}
          style={{
            position: "absolute",
            right: "20px",
            top: "25px",
            color: "green",
            cursor: "pointer",
          }}
          onClick={() => {
            clickOnChange({
              customer_address_id: address.id,
            });
          }}
        >
          <div>Change</div>
        </div>
      ) : null}
      {select ? (
        <div
          // className={styles.edit_operation}
          style={{
            position: "absolute",
            right: "20px",
            top: "25px",
            color: "green",
            cursor: "pointer",
          }}
          onClick={() => {
            setLoading(true);
            clickOnSelect({ customer_address_id: address.id }).finally(() => {
              setLoading(false);
            });
          }}
        >
          {loading ? <div>Loading...</div> : <div>Select</div>}
        </div>
      ) : null}
    </AddressTileBase>
  );
};
