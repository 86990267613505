import { useAppContext } from '@/core/App/AppContext'
import { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent'
import './quantity-counter.scss'
export default function QuantityInput({
  quantity,
  disable = false,
  setQuantity,
}) {
  const [open, openModal] = useState(false)
  const [{ mitter }] = useAppContext()
  const onQuantityChange = (quantity) => {
    setQuantity(quantity)
  }
  const limitIncrememnt = () => {
    {
      quantity < 20 ? increment() : openModal(true)
    }
  }

  const increment = () => {
    mitter.emit('NavigationStart', true)
    onQuantityChange(quantity + 1)
  }

  const decrement = () => {
    quantity > 1 && onQuantityChange(quantity - 1)
    quantity > 1 && mitter.emit('NavigationStart', true)
  }
  useEffect(() => {
    disable == false && mitter.emit('NavigationEnd', false)
  }, [quantity])
  return (
    <div className="field qty qty-wrapper">
      <label className="label">
        <span>Qty</span>
      </label>
      <div className="control">
        <div className="qty-arrows">
          {/* <input
            disabled={disable}
            type="button"
            className="qty-increase"
            onClick={limitIncrememnt}
          /> */}

          <button onClick={decrement} className="qty-decrease">
            <svg
              xmlns="
          http://www.w3.org/2000/svg"
              width="12"
              height="3"
              fill="none"
              viewBox="0 0 12 3"
            >
              <path
                fill="#818B5A"
                d="M10.828 0H1.09a.823.823 0 00-.812.834v.833c0 .46.364.834.812.834h9.737a.823.823 0 00.811-.834V.834A.823.823 0 0010.828 0z"
              ></path>
            </svg>
          </button>

          <input
            // {...inputProps}
            value={quantity}
            disabled={disable}
            type="text"
            id="quantity"
            title="Qty"
            className="input-text qty"
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '')
              // onChange(e)
            }}
          />
          <button className="qty-increase" onClick={limitIncrememnt}>
            <svg
              xmlns="
                  http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                fill="#818B5A"
                d="M11.054 4.585H7.403V.834A.823.823 0 006.59 0H5.78a.823.823 0 00-.812.834v3.75H1.317a.823.823 0 00-.812.834v.834c0 .46.364.834.812.834h3.651v3.75c0 .461.364.834.812.834h.811a.823.823 0 00.812-.833V7.086h3.65a.823.823 0 00.812-.834v-.834a.823.823 0 00-.811-.833z"
              ></path>
            </svg>
          </button>
          {/* <input
            disabled={disable}
            type="button"
            className="qty-decrease"
            onClick={decrement}
          /> */}
        </div>
      </div>
      <ModalComponent
        isOpen={open}
        close={(data) => openModal(data)}
        title={''}
      >
        <div>Maximum Limit Reached!!!</div>
      </ModalComponent>
    </div>
  )
}
