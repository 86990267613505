import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import razorpaypayment from '@/theme/lib/razorpaypayment'
import {
  placeOrderMutation,
  placeRazorpayOrder,
  setBillingAddressOnCart,
  setGuestEmailOnCart,
  setPaymentMethodOnCart,
  setRzpPaymentDetailsOnCart,
  setShippingAddressesOnCart,
  setShippingMethodsOnCart,
  triggerPendingOrder,
} from 'src/graphql/mutations/checkoutmutation'

export const setAddressOnCart = async (data, cart_id) => {
  const {
    paymentMethod,
    shippingMethod,
    email,
    firstname,
    lastname,
    company,
    street,
    city,
    region,
    postcode,
    country_code,
    telephone,
    customer_address_id,
    save_in_address_book,
  } = data

  const address = customer_address_id
    ? {
        customer_address_id,
      }
    : {
        address: {
          firstname,
          lastname,
          company,
          street,
          city,
          region,
          postcode,
          country_code,
          telephone,
          save_in_address_book: true,
        },
      }

  var cartUpdate = {}
  try {
    const shipping_address = await graphqlWebClient.request(
      // setting shipping address
      setShippingAddressesOnCart,
      { input: { cart_id, shipping_addresses: [address] } }
    )

    cartUpdate = {
      ...shipping_address?.setShippingAddressesOnCart?.cart,
    }
  } catch (e: any) {
    const resp = e.response
    if (resp) {
      const error = (resp.errors || []).find(
        (error) =>
          error.message !=
          'Shipping address is not allowed on cart: cart contains no items for shipment.'
      )
      if (error) {
        throw new Error(error.message)
      }
    }
  }

  const billing_address = await graphqlWebClient.request(
    // setting billing address
    setBillingAddressOnCart,
    {
      input: {
        cart_id,
        billing_address: { ...address, same_as_shipping: true },
      },
    }
  )

  cartUpdate = {
    ...cartUpdate,
    ...billing_address?.setBillingAddressOnCart?.cart,
  }
  return cartUpdate
}

export const placeOrderFlow = async (
  data,
  isAuthenticated,
  cart_id,
  razorpay_key_id
) => {
  const { paymentMethod, shippingMethod, email, firstname, telephone } = data
  let cartData = {}
  if (!isAuthenticated) {
    const set_guest_email_on_cart = await graphqlWebClient.request(
      // setting email for guest user
      setGuestEmailOnCart,
      { input: { cart_id, email } }
    )

    cartData = {
      ...cartData,
      ...set_guest_email_on_cart.setGuestEmailOnCart.cart,
    }
  }

  const set_shipping_method = await graphqlWebClient.request(
    setShippingMethodsOnCart,
    { input: { cart_id, shipping_methods: shippingMethod } }
  )

  cartData = {
    ...cartData,
    ...set_shipping_method.setShippingMethodsOnCart.cart,
  }

  const set_payment_method = await graphqlWebClient.request(
    // setting payment method as per user select
    setPaymentMethodOnCart,
    { input: { cart_id, payment_method: { code: paymentMethod } } }
  )

  cartData = {
    ...cartData,
    ...set_payment_method.setPaymentMethodOnCart.cart,
  }
  let hasToPay = true
  if (paymentMethod == 'cashondelivery' || paymentMethod == 'free') {
    hasToPay = false
  }

  if (hasToPay) {
    // only if it online payment
    const {
      placeRazorpayOrder: {
        success,
        rzp_order_id,
        amount,
        currency,
        order_quote_id,
        messgae,
      },
    } = await graphqlWebClient.request(placeRazorpayOrder(cart_id))
    if (success) {
      try {
        const razaorpayPayment: any = await razorpaypayment({
          razorpay_key_id,
          order_id: rzp_order_id,
          currency,
          amount,
          mobile: telephone,
          email,
          firstname,
          notes: {
            merchant_order_id: '',
            merchant_quote_id: order_quote_id,
          },
        })
        if (razaorpayPayment?.razorpay_modal_close) {
          graphqlWebClient
            .request(triggerPendingOrder, { quote_id: cart_id })
            .then((d) => {
              // success message
            })
            .catch((err) => {
              console.log(err)
            })
        } else if (razaorpayPayment.razorpay_order_id) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
            razaorpayPayment
          const setPaymentDetailsOnCartRzp = await graphqlWebClient.request(
            setRzpPaymentDetailsOnCart(
              cart_id,
              razorpay_payment_id,
              razorpay_order_id,
              razorpay_signature
            )
          )
        } else {
          return {
            cartData,
          }
        }
      } catch (err) {
        console.log('razarpay err', err)
        throw new Error('Razorpay payment failed')
      }
    } else {
      throw new Error(messgae)
    }
  }

  const {
    placeOrder: { order },
  } = await graphqlWebClient.request(placeOrderMutation, {
    input: { cart_id },
  })
  return { order, cartData }
}
