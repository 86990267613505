// import authService from './auth.service';
// import cartService from './cart.service';
// import { trackAPIResponseTime } from './getProductClick';
// import loadScript from './loadScript';

// import { loadScript } from "@/common/helpers";

declare var Razorpay;
type RazorpayInput = {
  razorpay_key_id: string;
  mobile: string;
  order_id: string;
  email: string;
  firstname: string;
  currency: string;
  amount: number;
  notes: any;
};
export default (inp: RazorpayInput) =>
  new Promise((resolve, reject) => {
    let {
      razorpay_key_id,
      mobile,
      email,
      firstname,
      currency,
      amount,
      order_id,
      notes = {},
    } = inp;

    var options = {
      // key: process.env.APP_RAZORPAY_KEY,
      key: razorpay_key_id,
      currency,
      amount: amount,
      name: "Moha India",
      description: "Moha India",
      order_id,
      handler: function (response) {
        resolve(response);
      },
      modal: {
        ondismiss: function () {
          resolve({ razorpay_modal_close: true });
        },
      },
      prefill: {
        name: firstname,
        email: email,
        contact: mobile,
      },
      theme: {},
    };
    // console.log('options',options)

    var rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert('Payment Failed. Please try again');
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();

    // let time_end = new Date().valueOf();

    // let time_take = (time_end - time_start) / 1000;

    // let productInfo = {
    //   source: 'razorpay payment modal',
    //   time: time_take,
    // };
    // trackAPIResponseTime(productInfo);
  });
