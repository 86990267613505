import { graphqlWebClient } from "@/theme/lib/graphqlClient"
import { FBCheckCustomer, GmailCheckCustomer } from "src/graphql/queries/loginQuery"


export const fbLogin = () => {
    return new Promise((resolve, reject) => {
        window.FB.getLoginStatus(({ status, authResponse }) => { // checking if customer is logged in
            if (authResponse) {
                graphqlWebClient.request(FBCheckCustomer(authResponse.accessToken)).then((data) => {
                    resolve({
                        status: data.custStatus.status,
                        token: data.custStatus.token,
                        access_token: authResponse.accessToken
                    })
                }).catch((err) => {
                    reject(err)
                })
            } else if (!authResponse) {
                window.FB.login(({ authResponse }) => {  // if not logged in pop will open
                    if (authResponse) {
                        graphqlWebClient.request(FBCheckCustomer(authResponse.accessToken)).then((data) => {
                            resolve({
                                status: data.custStatus.status,
                                token: data.custStatus.token,
                                access_token: authResponse.accessToken
                            })
                        }).catch((err) => {
                            reject(err)
                        })
                    }
                }, { scope: 'public_profile,email' });
            }
        })

    })
}

export const gmailLogin = (access_token) => {
    return new Promise((resolve, reject) => {
        graphqlWebClient.request(GmailCheckCustomer(access_token)).then((data) => {
            resolve({
                status: data.custStatus.status,
                token: data.custStatus.token
            })
        }).catch((err) => {
            reject(err)
        })
    })
}