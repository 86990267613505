import { useAppContext } from "@/core/App/AppContext";
import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import { getCartId } from "@/utils/cart";
import { useForm } from "react-hook-form";
import { mstRewardsApplyPointsToCart } from "src/graphql/mutations/customermutation";
import { aPIEvent, getError } from "src/services/ga";
import Button, { Buttons } from "../../Button";
import useCartContext from "../../CartWrapper/useCartContext";
import useAuthContext from "../../Contexts/AuthContext";
import Form from "../../Form";
import "./checkout-glow-point.scss";

export const CheckoutGlowPoint = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [
    {
      isAuthenticated,
      mstRewardPointsBalance: { balance },
    },
    authDispatch,
  ] = useAuthContext();
  const [{ mitter }] = useAppContext()
  const [cart, cartDispatch] = useCartContext();
  const {mstRewardPoints} = cart
  if (!isAuthenticated) {
    return null;
  }

  const onSubmit = async ({ glowpoints }) => {
    let date = new Date();
    try {
      const cart_id = await getCartId();
      const setRewardPoints = await graphqlWebClient.request(
        mstRewardsApplyPointsToCart(cart_id, glowpoints)
      );
      const {
        mstRewardsApplyPointsToCart: { cart },
      } = setRewardPoints;
      cartDispatch({
        type: "addProductToCart",
        payload: cart,
      });
      if(glowpoints == 0){
        mitter.emit("ShowToaster", {
          message: "Glow Points Removed",
        });  
      }
      else{
        mitter.emit("ShowToaster", {
          message: "Only 20% of the cart value can be redeemed by Glow Points.",
        });
        aPIEvent('Glow Point Added to Cart','success',date)
      }
    } catch (err) {
      console.log(err);
      aPIEvent('Glow Point Added to Cart',getError(err),date)
      alert("Something went wrong");
    }
  };

  return (
    <>
     {mstRewardPoints?.spend_points == 0 ? <div>
       <div className="glow-point-text">
          <b><span>{balance}</span></b> Glow Points are Available
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='glow-point-input'>
            <Form.TextField
              name="glowpoints"
              autoComplete="off"
              label="Enter Glow Points"
              {...register("glowpoints", {
                pattern: /^[0-9]*$/,
              })}
            ></Form.TextField>
            <div className="submit-button">
              <Buttons>
                <Button>Apply</Button>
              </Buttons>
            </div>
          </div>
        </form>
      </div> : <>
          <div className="glow-point-applied-text">
            <b>{mstRewardPoints?.spend_points}</b> worth of Glow Points applied to your cart.
            <div className='pointer primary-color'
              onClick={()=>onSubmit({glowpoints:0})}
            >(Remove)</div>
          </div>
        </> }
    </>
  );
};
