import Link from "@/core/Link";
import AccountLinks from "@/theme/components/Header/AccountLinks";
import clsx from "clsx";
import { useAppContext } from "@/core/App/AppContext";
import "./header-top.scss";
import { useRouterContext } from "@/core/Router/RouterContext";
import useCartContext from "../../CartWrapper/useCartContext";
import ModalComponent from "../../ModalComponent";
import { LoginComponent } from "../../LoginComponent";
import useAuthContext from "../../Contexts/AuthContext";
import LoginModal from "../../LoginComponent/LoginModal";
import { useEffect, useRef, useState } from "react";
import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import { productSearch } from "src/graphql/queries/productQuery";
import { Image } from "../../Image";
import HeaderBottom from '@/theme/components/Header/HeaderBottom'

export default function HeaderTop(props) {
  const [{ mitter, isMobile, content: offer_text }] = useAppContext();
  const [cartState] = useCartContext();
  const { items, total_quantity } = cartState;
  const cartItemsQty = items.reduce((acc, init) => init.quantity + acc, 0) || 0;
  const router = useRouterContext();
  const [auth] = useAuthContext();
  const [open, openModal] = useState(false);
  const inputEl = useRef(null);
  const [isSticky, setSticky] = useState(false)
  const [isScroll, checkScroll] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchProduct, setSerchProduct] = useState([]);

  function debounce(delay) {
    // this function is called when user search in searchbar
    let timeoutID;
    return function () {
      if (timeoutID) clearTimeout(timeoutID); // if there is continuos typing each time cleartimout will clear the timeoutId
      timeoutID = setTimeout(() => {
        console.log(!inputEl.current.value);
        if (!!inputEl.current.value) {
          graphqlWebClient
            .request(productSearch(inputEl.current.value))
            .then((data) => {
              setSerchProduct(data.products.items);
            });
        }
      }, delay);
    };
  }

  // {/* <OfferText/> */}
  const onClick = (e) => {
    mitter.emit('TOGGLE_NAV', e)
  }

  const yourCallbackHere = () => {
    if (window.pageYOffset > 0) {
      checkScroll(true)
    } else {
      checkScroll(false)
    }

    if (window.pageYOffset > 10) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', yourCallbackHere)
    return () => {
      document.removeEventListener('scroll', yourCallbackHere)
    }
  }, [yourCallbackHere])

  return (
    <>
      <div className={`header-top ${isSticky ? 'slidetop' : 'slidenormal'}`}>

        <div className="container-width">
          <div className="header-left blank">
            <span
              data-action="toggle-nav"
              className="nav-toggle icon"
              onClick={onClick}
            >
              <span>Toggle Nav</span>
            </span>
            <div className="block-search" id="block-search">
              <i className="fa fa-search sicon"></i>
            </div>
          </div>{' '}
          {/* header left */}
          <div className="header_center">
            <Link href="/" className="header-logo">
              <img className="div-logo" width="50%" src="https://moha-m.b-cdn.net/static/frontend/moha_logo_new_1.png?width=300" title="Moha" alt="Moha logo" />
            </Link>
          </div>
          <div className={`header_right header-right ${searchOpen ? 'is-expanded' : ''}`}>
            <div style={{ marginRight: '6px', width: '0', opacity: '0' }} className={`search-bar ${searchOpen ? 'is-open' : ''}`}>
              <input
                name="q"
                className="search-input input js-search-input"
                id="search-input"
                placeholder="What are you looking for today?"
              />
            </div>
            <div className={`search-block ${searchOpen ? 'is-open' : ''}`} id="search">
              <form>
                <img onClick={() => setSearchOpen(prev => !prev)} className="searchNew" src="https://moha-m.b-cdn.net/static/frontend/search-new.png" alt="" />
              </form>
            </div>
            <div
              className="headertoggle_img"
              onClick={() => {
                auth.isAuthenticated
                  ? router.push('/my-account/profile', {})
                  : mitter.emit('TOGGLE_LOGIN_MODEL')
              }}
            >
              <img src="https://moha-m.b-cdn.net/static/frontend/userNew.png" alt="" />
              <div className="panel header">
                <a className="action skip contentarea" href="#contentarea">
                  <span>Skip to Content</span>
                </a>
                <AccountLinks />
              </div>
            </div>
            <div
              className={clsx(
                'header-mincart cart-toggle'
                // cartState.openMiniCart && 'open-cart'
              )}
              onClick={() => {
                mitter.emit('MiniCartToggle')
              }}
            >
              <div className="minicart-wrapper">
                <img src="https://moha-m.b-cdn.net/static/frontend/cartMini.png" alt="" />
                <div className="action showcart"></div>
                <div className="cart-counter">
                  <span className="counter qty empty">
                    {cartItemsQty}
                    {/* <span className="counter-number">
                    </span> */}
                  </span>
                  {/* <span data-bind="html: getCartParam('subtotal')" className="subtotal"><span class="price">₹0.00</span></span> */}
                </div>
              </div>
            </div>
          </div>
          <LoginModal />
          <HeaderBottom />

        </div>
      </div>{' '}
    </>
  )
}
