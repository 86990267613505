import Form from '../Form'
import useConfigurableVariant from './useConfigurableVariant'
import useConfigurableOption from './useConfigurableOption'
import useProductContext from '@/theme/route/ProductPage/useProductContext'
import { isProductConfigurable } from '@/services/helpers/isProductType'

function ConfigurableOption(props) {
  const { label, uid } = useConfigurableOption(props)

  return <option value={uid}>{label}</option>
}

export default function ConfigurableProductV2(prop) {
  const [product, { selectOption }] = useProductContext()
  const { variants, configurable_options } = useConfigurableVariant(product)

  if (!isProductConfigurable(product)) return null

  const {
    cartItem: { selected_options },
  } = product

  if (configurable_options.length == 0) return null

  // This will only work if
  const code = configurable_options[0].attribute_code

  const value = selected_options[code]

  return (
    <fieldset className="fieldset">
      <Form.SelectField
        label={prop.label}
        onChange={(e) => {
          selectOption(code, e.currentTarget.value)
        }}
        value={value}
      >
        {variants.map((variant, index) => (
          <ConfigurableOption
            key={variant.product.sku}
            {...variant}
            index={index}
          />
        ))}
      </Form.SelectField>
    </fieldset>
  )
}
