import price_range from "./product/price_range"

const glowPoints = `
mstRewardPointsBalance {
  balance
  spent
  earned
  transactions{
    amount
    comment
    transaction_id
    created_at
    amount
    amount_used
    activated_at
    code
    comment
    is_activated
    is_expiration_email_sent
    is_expired
    expires_at
  }
}
`
const whistList = `wishlist {
  id
  items_v2 {
    items {
      product {
        pack_size
        sku
        bogo
        __typename
        rating_summary
        url_key
        name
        image {
          disabled
          label
          position
          url
        }
        price_range {
          ${price_range(true)}
        }
      }
    }
  }
}`
export const customer =  `
  customer {
    ${glowPoints}
    giftcards{
      card_amount
      card_balance
      card_code
      card_status
    }
    id
    share_and_save_url
    lastname
    firstname
    email
    date_of_birth
    anniversary
    webengage_whatsapp_optin
    mobilenumber 
    addresses{
      id
      firstname
      lastname
      street
      postcode
      telephone
      region{
        region
        region_id
        region_code
      }
      city
    }
  }
`

export const FBCheckCustomer = (access_token) => `
{
  custStatus(
  token:"${access_token}"
  type:"fb"
  )
  {
    status
    token
  }
}
`
export const GmailCheckCustomer = (access_token) => `
{
  custStatus(
  token:"${access_token}"
  type:"g"
  )
  {
    status
    token
  }
}
`

export const FBPostLogin = ({access_token,mobilenumber,otp,type='fb'}) => `
{
  postSocialLogin(
  token:"${access_token}"
  type:"${type}"
  mobilenumber:"${mobilenumber}"
  otp:"${otp}"
  )
  {
   status
    token
  }
}
  
  
`