import { RouteObject } from '@/core/Router/Router.Types'
import loadable from '@loadable/component'
const HomePage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "cms" */ '@/theme/route/HomePage'
    ),
  {
    ssr: true,
  }
)

const CartPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "cart" */ '@/theme/route/CartPage'
    ),
  {
    ssr: true,
  }
)
const CheckoutPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "checkout" */ '@/theme/route/CheckoutPage'
    ),
  {
    ssr: true,
  }
)
const MyAccount = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "account" */ '@/theme/route/MyAccountPage'
    ),
  {
    ssr: true,
  }
)
const PasswordChangePage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "misc" */ '@/theme/route/PasswordChangePage'
    ),
  {
    ssr: true,
  }
)
const SearchPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "category" */ '@/theme/route/SearchPage'
    ),
  {
    ssr: true,
  }
)
const ConfirmAccountPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "cms" */ '@/theme/route/ConfirmAccountPage'
    ),
  {
    ssr: true,
  }
)
const MenuPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "cms" */ '@/theme/route/MenuPage'
    ),
  {
    ssr: true,
  }
)
const WishlistShared = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "misc" */ '@/theme/route/WishlistSharedPage'
    ),
  {
    ssr: true,
  }
)
const ContactPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "contact" */ '@/theme/route/ContactPage'
    ),
  {
    ssr: true,
  }
)
const ProductComparePage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/ProductComparePage'
    ),
  {
    ssr: true,
  }
)
const CreateAccountPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/CreateAccountPage'
    ),
  {
    ssr: true,
  }
)
const LoginAccountPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/LoginAccountPage'
    ),
  {
    ssr: true,
  }
)
const ForgotPasswordPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/ForgotPasswordPage'
    ),
  {
    ssr: true,
  }
)
const MohaVideoPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/MohaVideoPage'
    ),
  {
    ssr: true,
  }
)
const MohaTvPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/MohaTvPage'
    ),
  {
    ssr: true,
  }
)
const FootCarePage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/FootCarePage'
    ),
  {
    ssr: true,
  }
)
const SolesForSmilesPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/SolesForSmilesPage'
    ),
  {
    ssr: true,
  }
)
const BABlanding = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/BABlanding'
    ),
  {
    ssr: true,
  }
)
const MusicalLandingPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/MusicalLandingPage'
    ),
  {
    ssr: true,
  }
)
const GoaStaycationContest = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/GoaStaycationContest'
    ),
  {
    ssr: true,
  }
)
const NailCareChallengePage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/NailCareChallengePage'
    ),
  {
    ssr: true,
  }
)
const MeetCelebrityChallengePage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/MeetCelebrityChallengePage'
    ),
  {
    ssr: true,
  }
)
const HairFallReductionMoneyBackChallenge = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/HairFallReductionMoneyBackChallenge'
    ),
  {
    ssr: true,
  }
)
const CorporateGiftingPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/CorporateGiftingPage'
    ),
  {
    ssr: true,
  }
)
const CheckoutSuccessPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "compare" */ '@/theme/route/CheckoutSuccessPage'
    ),
  {
    ssr: false,
  }
)
const MageRewrites = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "magerewrites" */ '@/theme/route/MageRewrites'
    ),
  {
    ssr: true,
  }
)
const BlogPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "blogpage" */ '@/theme/route/BlogPage'
    ),
  {
    ssr: true,
  }
)
const BlogMainPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "blogpage" */ '@/theme/route/BlogPage/BlogMainPage'
    ),
  {
    ssr: true,
  }
)
const NewsAndArticles = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "blogpage" */ '@/theme/route/NewsAndArticles'
    ),
  {
    ssr: true,
  }
)
const DigestWithDipya = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "blogpage" */ '@/theme/route/DigestWithDipya'
    ),
  {
    ssr: true,
  }
)

// /** @namespace Component/Router/Component/withStoreCode */
export const withStoreCode = (path) => path //window['storeRegexText'].concat(path);

const routes: RouteObject[] = [
  {
    path: withStoreCode('/'),
    exact: true,
    component: HomePage,
    position: 10,
    caseSensitive: false,
    initialDataPath: 'HomePage',
  },
  {
    path: withStoreCode('/searchanise/result'),
    component: SearchPage,
    position: 25,
    caseSensitive: false,
    initialDataPath: 'SearchPage',
  },
  {
    path: withStoreCode('/blog/:type/:url_key'),
    component: BlogPage,
    position: 40,
    caseSensitive: false,
    initialDataPath: 'BlogPage',
  },
  {
    path: withStoreCode('/blog'),
    component: BlogMainPage,
    position: 42,
    caseSensitive: false,
    initialDataPath: 'BlogMainPage',
  },
  {
    path: withStoreCode('/:account*/createPassword/'),
    component: PasswordChangePage,
    position: 60,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/:account*/create/'),
    component: CreateAccountPage,
    position: 61,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/:account*/login/'),
    component: LoginAccountPage,
    position: 62,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/:account*/forgotpassword/'),
    component: ForgotPasswordPage,
    position: 63,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/:account*/confirm'),
    component: ConfirmAccountPage,
    position: 65,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/my-account/:tab?'),
    component: MyAccount,
    position: 70,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/forgot-password'),
    component: MyAccount,
    position: 71,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/menu'),
    component: MenuPage,
    position: 80,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/wishlist/shared/:code'),
    component: WishlistShared,
    position: 81,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/contact'),
    component: ContactPage,
    position: 82,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/compare'),
    component: ProductComparePage,
    position: 83,
    caseSensitive: false,
  },
  // {
  //   path: withStoreCode('/product-category/:slug*'),
  //   component: SampleCategoryPage,
  //   position: 84,
  //   caseSensitive: false,
  //   initialDataPath: 'SampleProductPage',
  // },
  // {
  //   path: withStoreCode('/product/:slug*'),
  //   component: SampleProductPage,
  //   position: 85,
  //   initialDataPath: 'SampleProductPage',
  // },
  {
    path: withStoreCode('/tv'),
    component: MohaTvPage,
    caseSensitive: false,
    position: 86,
    initialDataPath: 'MohaTvPage',
  },
  {
    path: withStoreCode('/video/:tab'),
    component: MohaVideoPage,
    position: 89,
    initialDataPath: 'MohaVideoPage',
  },
  {
    path: withStoreCode('/checkout/order-received'),
    component: CheckoutSuccessPage,
    position: 90,
  },
  {
    path: withStoreCode('/footcare'),
    component: FootCarePage,
    position: 91,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/soles-for-smiles'),
    component: SolesForSmilesPage,
    position: 91,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/corporate-gifting'),
    component: CorporateGiftingPage,
    position: 92,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/checkout/:step?'),
    component: CheckoutPage,
    position: 93,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/nailcare-challenge'),
    component: NailCareChallengePage,
    position: 94,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/chance-to-meet'),
    component: MeetCelebrityChallengePage,
    position: 101,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/bride-brigade'),
    component: BABlanding,
    position: 95,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/bigfan'),
    component: MusicalLandingPage,
    position: 96,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/goa-staycation-contest'),
    component: GoaStaycationContest,
    position: 97,
    caseSensitive: false,
  },
  // {
  //   path: withStoreCode('/moha-hair-fall-reduction-money-back-challenge'),
  //   component: HairFallReductionMoneyBackChallenge,
  //   position: 98,
  //   caseSensitive: false,
  // },
  {
    path: withStoreCode('/news-and-articles'),
    component: NewsAndArticles,
    position: 99,
    caseSensitive: false,
  },
  {
    path: withStoreCode('/digest-with-dipya'),
    component: DigestWithDipya,
    position: 100,
    caseSensitive: false,
  },

  {
    path: null,
    component: MageRewrites,
    position: 1000,
    caseSensitive: false,
    initialDataPath: 'MageRewrites',
  },
]

export default routes
