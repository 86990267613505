import React from "react";
import { useState } from "react";
import Form from "@/theme/components/Form";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Button, { Buttons } from "@/theme/components/Button";
import "./emailcart.scss";
import useCartContext from "../CartWrapper/useCartContext";
import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import { emailQuote } from "src/graphql/mutations/customermutation";
import { useAppContext } from "@/core/App/AppContext";

export const EmailCart = (props) => {
  const {
    handleSubmit,
    register,
  } = useForm();
  const [cart, , { getQuoteId }] = useCartContext();
  const [{mitter}] = useAppContext();
  const { close } = props;
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    getQuoteId()
      .then((guest_cart_id) => {
        return graphqlWebClient.request(emailQuote, {
          ...data,
          guest_cart_id,
        });
      })
      .then(({ EmailQuote: { message, status } }) => {
        if (status) {
            mitter.emit('ShowToaster', { message: 'Send email quote' })
            close(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <div className="emailcart-body">
      <p className="email-popup-text">
        Add a name and email you'd like to send your cart information to:
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="email-cart-input">
          <Form.TextField label="First Name" {...register("first_name")} />
          <Form.TextField label="Last Name" {...register("last_name")} />
          <Form.TextField label="Email" {...register("email")} />
          <Form.TextField
            label="Mobile Number"
            {...register("phone", {
              pattern: /^\d{10}/,
            })}
          />
          <Form.TextareaField
            placeholder={"Message you'd like to send recipient(optional)"}
            className="email-cart-text-area"
            label="Message"
            {...register("comment")}
          />
        </div>
        <div className="email-input-submit">
            <Button>Submit</Button>
        </div>
      </form>
    </div>
  );
};
