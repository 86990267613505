import styles from './checkout-message.module.scss'
import clsx from 'clsx';

export const CheckoutMessageTile = (
    {   type = 'default',
        color = 'default',
        children 
    }) => {
    let checkout_msg = `checkout_message_${type}`
    return (
        <div className='checkout-main-new'>
            <div className={clsx(
                styles[checkout_msg], 
                styles[color] )
            }>
                {children}
            </div>
        </div>
    )
}