import { FC, useEffect } from 'react'
import Button from '../Button'
import CheckIcon from './CheckIcon'
import { useFormContext, useFormState } from 'react-hook-form'
import useScript from '../../../../hooks/useScript'

const msg91Script = `https://control.msg91.com/app/assets/otp-provider/otp-provider.js`

const msg91Config = {
  exposeMethods: false,
  widgetId: '3461796c3250373931393539',
  tokenAuth: '369870TWo6hwdE77i65b20ce1P1',
  // identifier: '91' + phoneNumber,
}

export const sendOTPAsync = (phoneNumber: string) =>
  new Promise((resolve, reject) => {
    try {
      initSendOTP({
        ...msg91Config,
        identifier: '91' + phoneNumber,
        success: (data) => {
          // get verified token in response
          resolve(data)
          console.log('success response', data)
        },
        failure: (error) => {
          // handle error
          console.log('failure reason', error)
          reject(error)
        },
      })
    } catch (error) {
      reject(error)
    }
  })

const OTPVerificationWidget: FC<{
  phoneNumber: string
  onSuccess: Function
  isOTPVerified: boolean
}> = ({ phoneNumber, onSuccess, isOTPVerified }) => {
  const script = useScript(msg91Script)

  // const field = useFormState({ control, name: 'telephone' })

  const smsPayload = {
    ...msg91Config,
    identifier: '91' + phoneNumber,
    success: (data) => {
      // get verified token in response
      onSuccess && onSuccess(data)
      console.log('success response', data)
    },
    failure: (error) => {
      // handle error
      console.log('failure reason', error)
    },
  }

  const onSendOTPClick = (event) => {
    event.preventDefault()
    // window.sendOtp(smsPayload.identifier, (data) => console.log('OTP sent successfully.', data), (error) => console.log('Error occurred', error))
    if (phoneNumber) {
      initSendOTP(smsPayload)
    }
  }
  // if (isOTPVerified) {
  //    return null
  // }

  return (
    <div>
      {isOTPVerified ? (
        <div style={{ paddingTop: '20px' }}>
          <CheckIcon /> <span>OTP Verified</span>
        </div>
      ) : (
        <div>
          <Button
            type="button"
            disabled={!phoneNumber}
            onClick={onSendOTPClick}
          >
            Send OTP
          </Button>
        </div>
      )}
      {/* {field.} */}
      {/* {phoneNumber} */}
    </div>
  )
}

export default OTPVerificationWidget
