import { useState } from "react";
import Button, { Buttons } from "@/theme/components/Button";
import { Currency } from "@/services/helpers/currency";
import { useAppContext } from "@/core/App/AppContext";
import { useRouterContext } from "@/core/Router/RouterContext";
import "./cart-footer.scss";
import { checkFreeShipping } from "../../Checkout/checkFreeShipping";
import ModalComponent from "../../ModalComponent";
import { EmailCart } from "../../EmailCart";
import { sliderProductRefactor } from "@/theme/route/MageRewrites/sliderProductRefactor";
import ProductSlider from "../../ProductSlider";
import { fBInitiateCheckout } from "src/services/fbEvents";
import useCartContext from "../useCartContext";

export const CartFooter = ({ cart }) => {
  const { prices, extentionAttr, filler_products, hasOnlyGiftCard } = cart;
  const [{ mitter }] = useAppContext();
  const router = useRouterContext();
  const [open, openModal] = useState(false);
  const [openLimitModal, setopenLimitModal] = useState(false);
  const [openFiller, setOpenFiller] = useState({
    tempClose: false,
    permanentClose: false,
  });

  const fillers = filler_products[0]?.items || [];


  const [cartState, cartDispatch, { removeItem, updateCartItem }] =
  useCartContext()
  const { items } = cartState;

//  const quantityCheck = ()=> {items.length > 0 &&
//       items.map((items, index) => {
//       const {
//         quantity,
//       } = items;
//       let finder;
//       if(quantity <= 10 ){finder=true}
//       else{finder=false}
//     })
//     return(
//       finder= finder &&
//     );
//   }
  return (
    <div className={"cart-footer"}>
      <div className={"final-amount"}>
        Total Amount
        <span className={"current-price"}>
          {" "}
          {Currency({ money: checkFreeShipping(extentionAttr, prices) })}{" "}
        </span>
      </div>
      {/* <Buttons>
        <Button
          onClick={() => {
            mitter.emit("MiniCartToggle");
            openModal(true);
          }}
          className={"checkout"}
          variant="primary"
        >
          Email Cart
        </Button>
      </Buttons> */}

      <div className="cart-footer-buttons">
        <Buttons>
          <Button
            onClick={() => {
              mitter.emit("MiniCartToggle");
              router.push("/shop", {})
            }}
            className={"shopping"}
            variant="primary"
          >
            Continue Shopping
          </Button>
        </Buttons>
        <Buttons>
          <Button
            onClick={() => {
              fBInitiateCheckout(cart);
              if (filler_products.length > 0 && !hasOnlyGiftCard) {
                if (!openFiller.tempClose && !openFiller.permanentClose) {
                  setOpenFiller({
                    tempClose: true,
                    permanentClose: false,
                  });
                  mitter.emit("MiniCartToggle");
                } else {
                  mitter.emit("MiniCartToggle");
                  router.push("/checkout", {});
                }
              } else {
                mitter.emit("MiniCartToggle");
                router.push("/checkout", {});
              }
            }}
            className={"checkout"}
            variant="primary"
          >
            Checkout
          </Button>
        </Buttons>
      </div>
      <ModalComponent
        isOpen={!hasOnlyGiftCard && openFiller.tempClose && fillers.length}
        close={(data) => {
          setOpenFiller({
            permanentClose: true,
            tempClose: false,
          })
          router.push("/checkout", {});
        }
        }
        title={"Add products to avail free shipping"}
        className='cartfree-products'
      >
        <ProductSlider items={fillers} title={""} slides={2} />
      </ModalComponent>
      <ModalComponent
        isOpen={open}
        close={(data) => openModal(data)}
        title={"Email Cart"}
      >
        <EmailCart close={(data) => openModal(data)} />
      </ModalComponent>

      <ModalComponent
        isOpen={openLimitModal}
        close={(data) => setopenLimitModal(data)}
        title={""}
      >
        <div>Please order below Rs.5000/-</div>
      </ModalComponent>
    </div>
  );
};
