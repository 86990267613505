import { gql } from "graphql-request";

export const scratchCardData = gql`{
  scratchCardData {
    display_pages_list
    cookie_time
    cover_image
    display_pages_list
    enable
    html_content
  }
  getscratchCardCoupon {
    coupon_code
    description
    name
  }
}`