import { useAppContext } from "@/core/App/AppContext";
import { useState } from "react";
import useCartContext from "../../CartWrapper/useCartContext";
import { CouponCode } from "../../CouponCode";
import ModalComponent from "../../ModalComponent";
import "./coupon-code.scss";

export const CheckoutCoupon = (props) => {
  const { isCartOpen = false } = props
  const [cartState, , { removeCoupon }] =
    useCartContext();
  const [{ mitter, isMobile }] = useAppContext();
  const [open, openModal] = useState(false);
  const couponOnCart = cartState?.applied_coupons && cartState?.applied_coupons[0]?.code;

  const handleRemoveCoupon = () => {
    removeCoupon().then((d) => {
      mitter.emit("ShowToaster", {
        message: "Coupon Code removed",
      });
    });
  };

  return (
    <>
      <div className="coupon-wrapper">
        {couponOnCart ? (
          <div className="coupon-info">
            <span className="coupon-code">{couponOnCart}</span>
            <div className="coupon-name-container">
              <i
                className="fa fa-close"
                onClick={() => handleRemoveCoupon()}
              ></i>
            </div>
          </div>
        ) : (
          <div className="coupon-info">
            <span className="">Coupons</span>
            <span
              className="coupon-action"
              onClick={(e) => {
                e.stopPropagation();
                openModal(true);
                isCartOpen && mitter.emit("MiniCartToggle");
              }}
            >
              Apply Coupon
            </span>
          </div>
        )}
      </div>
      <ModalComponent
        isOpen={open}
        close={(data) => openModal(data)}
        title={`${couponOnCart ? 'Coupon Applied' : 'Apply Coupons'}`}
      >
        <CouponCode isOpen={open} close={(data) => openModal(data)} />
      </ModalComponent>
    </>
  );
};
