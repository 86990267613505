import Button, { Buttons } from "../../Button";
import ModalComponent from "../../ModalComponent";
import { ShippingForm } from "../../Checkout/CheckoutShipping";
import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";
import useAuthContext, { AuthActions } from "../../Contexts/AuthContext";
import { createCustomerAddress } from "src/graphql/mutations/customermutation";
import { customer } from "src/graphql/queries/loginQuery";
import styles from "./address.module.scss";
import { graphqlWebClient } from "@/theme/lib/graphqlClient";

export const AddNewAddress = () => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;
  const [cityState, setCityState] = useState({});
  const [open, openModal] = useState(false);
  const [auth, authDispatch] = useAuthContext();
  const onAddAddress = async (data) => {
    const {
      city,
      country_code,
      firstname,
      id,
      lastname,
      postcode,
      region,
      region_name,
      region_id,
      street,
      telephone,
    } = data;

    const createCustomer = {
      firstname,
      lastname,
      city,
      region: {
        region: region_name,
        region_code: region,
        region_id,
        // region_id: cityState.region_id || address.region?.region_id,
      },
      postcode,
      street,
      telephone,
      country_code: 'IN'
    };

    try {
      const { createCustomerAddress: newAddress } =
        await graphqlWebClient.request(createCustomerAddress, {
          input: createCustomer,
        });
      if (newAddress) {
        authDispatch({
          type: AuthActions.ADD_ADDRESS,
          payload: newAddress,
        });
        openModal(false)
        alert("Address Added Success");
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong");
    }
  };
  return (
    <>
      <Buttons>
        <Button
          onClick={() => {
            openModal(true);
          }}
        >
          New Address
        </Button>
      </Buttons>
      <ModalComponent
        isOpen={open}
        close={(data) => openModal(data)}
        title={"New Address"}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onAddAddress)}
            className={styles.address_new}
          >
            <ShippingForm disableEmail />
            <Buttons>
              <Button>Add</Button>
            </Buttons>
          </form>
        </FormProvider>
      </ModalComponent>
    </>
  );
};
