import PersistanceStorage from "@/utils/storage";

const storage = new PersistanceStorage();
export const gtm = (gtmData) => {
  try {
    console.log('__GTM PUSH__', gtmData.event, gtmData)
    gtmData.uid = {
      email: storage.getItem('email')
    }
    window['dataLayer'].push(gtmData)
  } catch (e) {}
}

export const sendGtmEvent = (event, obj) => {
  return gtm({
    event,
    ...obj,
  })
}

function getCartItems(cart) {
  return cart.items.map(
    ({
      quantity,
      product: { name, categories, sku, size, pack_size },
      prices: {
        price: { value: price },
      },
    }) => {
      let variant = size || pack_size
      return {
        name: name,
        price,
        sku,
        id: sku,
        variant,
        quantity,
      }
    }
  )
}

export const sendCartLoaded = (cart) => {
  try {
    let shipping_addresses = cart.shipping_addresses || []
    let shipping_address = shipping_addresses[0] || {}

    gtm({
      event: 'cartLoaded',
      cart: {
        items: getCartItems(cart),
        address: shipping_address,
      },
    })
  } catch (e) {}
}
export const sendUserLoaded = (user) => {
  try {
    if (!user) {
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: false,
        },
      })
    } else {
      const { email, firstname, lastname } = user
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: true,
          email,
          firstname,
          lastname,
        },
      })
    }
  } catch (e) {}
}
export const sendPageView = (page) => {
  try {
    gtm({
      event: 'pageView',
      page,
    })
  } catch (e) {}
}

export const sendPDPEvent = (product) => {
  try {
    const {
      name,
      price,
      sku,
      brand,
      manufacturer,
      list_price,
      amount_off,
      image,
      currency,
    } = product
    gtm({
      event: 'productDetailView',
      pagePath: window.location.href,
      pageType: 'product',
      pageTitle: name,
      items: [
        {
          id: sku,
        },
      ],
      ecommerce: {
        detail: {
          // actionField: { list: 'Apparel Gallery' }, // 'detail' actions have an optional list property.
          products: [
            {
              name,
              id: sku,
              sku,
              price,
              brand,
              manufacturer,
              list_price,
              amount_off,
              image,
              currency,
            },
          ],
        },
      },
    })
  } catch (e) {}
}
export const sendPLEvent = (category, products) => {
  try {
    gtm({
      event: 'productListView',
      ecommerce: {
        currencyCode: 'INR', // Local currency is optional.
        impressions: products.map((product, index) => ({
          name: product.name,
          id: product.sku,
          sku: product.sku,
          price: product.price_range.minimum_price.final_price.value,
          category: category.name,
          position: +index + 1,
        })),
      },
    })
  } catch (e) {}
}
export const cartViewEvent = (cart) => {
  try {
    gtm({
      event: 'cartView',
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Visa' },
          products: getCartItems(cart),
        },
      },
    })
  } catch (e) {}
}
export const sendAddToCartEvent = (product, cartItem) => {
  try {
    let { name, price, sku: id, size, pack_size } = product
    let { quantity } = cartItem
    let variant = size || pack_size;
    // categoryId:`${product?.categories?.map((i)=>(i?.uid))}`,
    // categoryName:`${product?.categories?.map((i)=>(i?.name))}`,

    gtm({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'INR',
        items: [
          {
            id,
          },
        ],
        add: {
          products: [
            {
              name: name,
              price,
              id,
              sku: id,
              variant,
              quantity,
            },
          ],
        },
      },
    })
  } catch (e) {}
}
export const removeFromCartEvent = (items) => {
  try {
    gtm({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          // 'remove' actionFieldObject measures.
          products: getCartItems({ items }),
        },
      },
    })
  } catch (e) {}
}


export const checkoutEvent = (cart) => {
  try {
    gtm({
      event: 'checkout',
      ecommerce: {
        checkout: {
          products: getCartItems(cart),
          actionField: { step: 1, option: 'Checkout' },
        },
      },
    })
  } catch (e) {}
}
export const purchaseEvent = (cart, order_number) => {
  try {
    let tax =
      cart.prices.subtotal_including_tax.value -
      cart.prices.subtotal_excluding_tax.value
    let shippingAddress = (cart.shipping_addresses || [])[0] || {}
    let shipping_amount =
      shippingAddress?.selected_shipping_method?.amount.value

    gtm({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: order_number, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue: cart.prices.grand_total?.value,
            tax: tax,
            shipping: shipping_amount,
            coupon: cart.applied_coupons,
          },
          products: getCartItems(cart),
        },
      },
    })
  } catch (e) {
    console.log(e)
  }
}
