import { useEffect, useMemo, useRef, useState } from 'react'
import scratchScript from './scratchScript'
import './scratchCart.scss'
import { createPortal } from 'react-dom'
import { useAppContext } from '@/core/App/AppContext'
import { scratchCardData } from './scratchCart.gql'
import useGraphQLQuery from '@/theme/lib/useGraphQLQuery'
import { useRouterContext } from '@/core/Router/RouterContext'
import PersistanceStorage from '@/utils/storage'
const storage = new PersistanceStorage()
export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text).then((d) => {
      alert('Code copied. You can close the popup')
    })
  } else {
    document.execCommand('copy', true, text)
    alert('Code copied. You can close the popup')
  }
}

const ScratchCardView = (props) => {
  const {
    cover_image,
    html_content,
    cookie_time,
    close,
    coupon_code,
    description,
    name,
  } = props
  const ref = useRef()
  useEffect(() => {
    if (ref.current) {
      scratchScript(ref.current, { cover_image })
    }
  }, [ref])
  useEffect(() => {
    const order_number = storage.getItem('order_number')
    if (!cookie_time)
      storage.setItem(`ScratchCardViewed-${order_number}`, '1', 86400 * 7)
    else storage.setItem(`ScratchCardViewed-${order_number}`, '1', cookie_time)
  }, [cookie_time])

  const renderedHtml = useMemo(() => {
    return html_content
      .replace('{name}', name)
      .replace('{couponcode}', coupon_code)
      .replace('{description}', description || '')
  }, [html_content, name, coupon_code])

  return (
    <div className='cisc-fixed'>
      <div
        className="cisc-modal"
        // style={{
        //   opacity: '1',
        //   top: '11px',
        //   left: '570px',
        // }}
      >
        <div className="cisc-main" id="scratch-container">
          <canvas
            className="canvas"
            id="scratch-canvas"
            width="300"
            height="300"
            ref={ref}
          ></canvas>
          <div
            className="code-content"
            id="secret-code"
            style={{
              visibility: 'visible',
            }}
          >
            <div className="code-area">
              <p
                style={{
                  textAlign: 'center',
                }}
              >
                <strong>
                  Congratulations!&nbsp;
                  <br />
                </strong>
                <strong>You have Won</strong>
              </p>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: renderedHtml,
                }}
              ></div> */}
              {/* <h2
                style={{
                  textAlign: 'center',
                }}
              >
                <strong>{name}</strong>
              </h2> */}
              <p
                style={{
                  textAlign: 'center',
                }}
                dangerouslySetInnerHTML={{
                  __html: renderedHtml,
                }}
              >
                {/* <a href="#"></a> */}
              </p>
              <div
                className="txt-code"
                id="txt-code-id"
                onClick={(e) => {
                  copyTextToClipboard(coupon_code)
                }}
              >
                <a href="#">
                  <span id="copycouponcode">{coupon_code}</span>
                  <span>
                    <img
                      className="sc-copy"
                      src="http://demo.cynoinfotech.com/d2m2/pub/static/version1632233370/frontend/Magento/luma/en_US/Cynoinfotech_ScratchCouponCard/images/sc-copy.png"
                    />
                  </span>
                </a>
              </div>
              <p></p>
              <h6
                style={{
                  textAlign: 'center',
                }}
              >
                Copy &amp; use above code within 15 days on your next checkout
              </h6>
            </div>
          </div>
        </div>
        <button className="cisc-closeBtn" onClick={close}>
          X
        </button>
      </div>
      <div
        className="cisc-overlay"
        style={{
          display: 'block',
          opacity: '0.8',
        }}
      ></div>
    </div>
  )
}
const Portal = ({ children, open }) => {
  const ref = useRef(null)
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    const el = document.createElement('div')
    ref.current = el
    const modalRoot = document.body
    if (modalRoot) {
      modalRoot.appendChild(el)
      setMounted(true)
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el)
      }
    }
  }, [])

  return ref.current && open ? createPortal(children, ref.current) : null
}

const ScratchCardBase = (props) => {
  const [{ data }] = useGraphQLQuery(scratchCardData, null, {
    immediate: true,
    authorized: true,
  })
  const enable = data?.scratchCardData.enable == '1'
  const display_pages_list = data?.scratchCardData.display_pages_list

  if (enable)
    return (
      <ScratchCardView
        {...(data?.scratchCardData || {})}
        {...(data?.getscratchCardCoupon || {})}
        close={() => {
          props.load(false)
        }}
      />
    )
  return null
}

const ScratchCard = (props) => {
  const [loaded, load] = useState(false)

  const [{ mitter }] = useAppContext()
  const loadRef = useRef(true)
  const router = useRouterContext()

  useEffect(() => {
    if (router.location.pathname.includes('/checkout/order-received')) {
      const order_number = storage.getItem('order_number')

      if (storage.getItem(`ScratchCardViewed-${order_number}`) == '1') {
      } else {
        load(true)
      }
    }
  }, [router.location])

  useEffect(() => {
    // const listener = function (e) {
    //   load(true)
    //   alert('hello');
    //   e.preventDefault()
    //   e.returnValue = ''
    // }
    // window.addEventListener('beforeunload', listener)
    // return () => {
    //   window.removeEventListener('beforeunload', listener)
    // }
  }, [
    load,
    /* mitter */
  ])

  if (!loaded) return null
  return (
    <Portal open>
      <ScratchCardBase load={load} />
    </Portal>
  )
}

export default ScratchCard
