import { Component, createElement, FC, forwardRef, ReactNode, useCallback } from 'react';
import { isLocalURL } from '@/utils/router';
import { useRouterContext } from '@/core/Router/RouterContext';
interface LinkProps {
  shallow?: boolean;
  replace?: boolean;
  href?: string;
  query?: Object;
  state?: Object;
  component?: ReactNode;
  className?: string;
  target?: string;
  id?: string;
  style?: any;
  onClick?: (e: React.MouseEvent) => any;
  getRef?: ((e: any) => any) | any;
}

export const resolveToLocation = (to, currentLocation) =>
  typeof to === 'function' ? to(currentLocation) : to;

function isModifiedEvent(event: React.MouseEvent): boolean {
  const { target } = event.currentTarget as HTMLAnchorElement;
  return (
    (target && target !== '_self') ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey || // triggers resource download
    (event.nativeEvent && event.nativeEvent.which === 2)
  );
}

function linkClicked(
  e: React.MouseEvent,
  router: any,
  href: any,
  replace?: boolean,
  shallow?: boolean,
  scroll?: boolean,
  locale?: string | false
): void {
  const { nodeName } = e.currentTarget;

  if (nodeName === 'A' && (isModifiedEvent(e) || !isLocalURL(href))) {
    // ignore click for browser’s default behavior
    return;
  }
  e.preventDefault();

  //  avoid scroll for urls with anchor refs
  if (scroll == null && href.indexOf('#') >= 0) {
    scroll = false;
  }
  router[replace ? 'replace' : 'push'](href, {
    shallow,
    locale,
    scroll,
  });
}

const Link: FC<LinkProps> = ({
  component = 'a',
  replace,
  href,
  shallow,
  getRef,
  onClick,
  ...rest
}) => {
  const router = useRouterContext();
  // const location = useLocation();
  // const path = useResolvedPath(to);
  // const href = useHref(to);

  // const router = useRouterContext();
  // const { history } = router;
  // const { ref, inView, entry } = useInView({ threshold: 0 });
  // const setRef = useCallback(
  //   (el: Element) => {
  //     ref(el);
  //     if (forwardedRef) {
  //       if (typeof forwardedRef === 'function') forwardedRef(el);
  //       else if (typeof forwardedRef === 'object') {
  //         forwardedRef.current = el;
  //       }
  //     }
  //   },
  //   [ref],
  // );

  const props = {
    ...rest,
    href,
    ref: getRef,
    onClick: (e: React.MouseEvent) => {
      if (typeof onClick === 'function') {
        onClick(e);
      }
      // const location = resolveToLocation(to, router.location);
      if (!e.defaultPrevented) {
        linkClicked(e, router, href, replace, shallow, false, false);
      }
    },
  };

  return createElement(component as any, props);
};

export default Link;
