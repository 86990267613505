import { useAppContext } from "@/core/App/AppContext";
import { useEffect, useState } from "react";
import { LoginComponent } from ".";
import useAuthContext from "../Contexts/AuthContext";
import { ModalComponent } from "../ModalComponent";

export default function LoginModal() {
  const [{ open, message }, setOpen] = useState({ open: false, message: null });
  const [{ mitter }] = useAppContext();
  useEffect(() => {
    function fn(message) {
      setOpen({ open: !open, message });
    }
    mitter.on("TOGGLE_LOGIN_MODEL", fn);
    return () => mitter.off("TOGGLE_LOGIN_MODEL", fn);
  }, [open]);

  const closeModal = () => {
    setOpen({
      open: false,
      message: null,
    });
  };
  return (
    <ModalComponent isOpen={open} close={closeModal} title={message}>
      <LoginComponent close={closeModal} message={message} />
    </ModalComponent>
  );
}
