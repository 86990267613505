import { Currency } from '@/services/helpers/currency';
import atob from 'atob-lite';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export default function useConfigurableOption(props) {
  const {
    product,
    attributes
  } = props;

  const label = attributes[0].label;
  const code = attributes[0].code;
  const uid = attributes[0].uid;
  const price = product.price_range.minimum_price.final_price.value
  const currency = product.price_range.minimum_price.final_price.currency

  return {
    label: `${label} - ${Currency({ money: price, currency })}`,
    code,
    uid
  };
}
