export const getProductDefaultCartItem = (productItem) => {
  productItem.defaultCartItem = {
    sku: productItem.sku,
    quantity: 1,
    bogo: productItem.bogo,
    id:productItem.id
  };

  
  if (productItem.variants && productItem.variants[0]) {
    // only for configurable product
    const [sort] = productItem.variants.sort((a, b) => {
      return (
        b.product.price_range.minimum_price.final_price.value -
        a.product.price_range.minimum_price.final_price.value
      );
    });
    const uid = productItem.variants[0].attributes[0].uid;
    const code = productItem.variants[0].attributes[0].code;

    if (!productItem.defaultCartItem.selected_options)
      productItem.defaultCartItem.selected_options = {};

    productItem.defaultCartItem.selected_options[code] = uid;
    const {
      product: {
        price_range: {
          minimum_price: { final_price },
        },
      },
    } = sort;
    productItem["as_low_as"] = final_price.value;
  } else {
    productItem.variants = [];
    productItem.configurable_options = [];
  }
  return productItem;
};
