import React, { useEffect } from "react";
import { useState } from "react";
import Form from "@/theme/components/Form";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import Button, { Buttons } from "@/theme/components/Button";
import "./couponcode.scss";
import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import { getCoupon } from "src/graphql/queries/getCoupon";
import { CouponList } from "./CouponList";
import useCartContext from "../CartWrapper/useCartContext";
import { useAppContext } from "@/core/App/AppContext";
import { Currency } from "@/services/helpers/currency";

export const CouponCode = ({ isOpen, close }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [coupons, setCoupons] = useState([]);

  const [successModal, setSuccessModal] = useState(false);
  const [cartState, cartDispatch, { applyCoupon }] = useCartContext();
  const [{ mitter }] = useAppContext();
  const couponCode = cartState?.applied_coupons && cartState?.applied_coupons[0]?.code;
  const couponOnCartValue = cartState.prices.discounts && cartState.prices.discounts[0].amount.value;

  const onSubmit = (data) => {
    const { couponcode } = data;
    applyCoupon(couponcode).then((data) => {
      mitter.emit("ShowToaster", {
        message: "Coupon Code applied",
      });
    }).catch((err) => {
      mitter.emit("ShowToaster", {
        message: err.message ?? "Coupon Code not valid",
      });
    })
  }
  useEffect(() => {
    const fetchCouponList = async () => {
      try {
        const { getCouponslist } = await graphqlWebClient.request(getCoupon);
        if (getCouponslist) {
          setCoupons(getCouponslist);
        } else {
          setCoupons([]);
        }
      } catch (err) {
        alert(err);
      }
    };
    fetchCouponList();
  }, [isOpen]);

  return (
    <>
      {!successModal ? <div className="coupon-modal-body">
        <form onSubmit={handleSubmit(onSubmit)} className="coupon-modal-form">
          <Form.TextField
            placeholder={"Enter Your Promo Code"}
            label="Coupon Code"
            className="coupon-code"
            {...register("couponcode", {
              maxLength: 255,
            })}
          ></Form.TextField>
          {/* <button className='apply-button' type='submit' /> */}
          <Buttons>
            <Button className="apply-button" type="submit">
              Apply
            </Button>
          </Buttons>
        </form>
        <small className="danger"></small>
        {coupons.map((coupon, i) => {
          const title = coupon.rule_title;
          const description = coupon.rule_description;
          const code = coupon.coupon_code;
          return (
            <CouponList
              key={i}
              title={title}
              description={description}
              code={code}
              close={close}
              setSuccessModal={setSuccessModal}
            />
          );
        })}
      </div> : <div className='coupon-success-body'>
        <div>
          <img src="/success.png" alt="success" width='15%' />
        </div>
        <div className='success-code-content'>
          Code <b>{couponCode}</b> applied!
        </div>
        <div className='success-amount-content'>
          <div> You saved </div>
          <div className='amount'>{Currency({money:couponOnCartValue})}</div>
          <div>
            with this promo code
          </div>
        </div>
        <div className='primary-color'>
          Woohoo! Thanks
        </div>
      </div>}
    </>
  );
};
