import { useAppContext } from '@/core/App/AppContext';
import Link from '@/core/Link';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import AccountLinks from '@/theme/components/Header/AccountLinks';
import './header-bottom.scss';
import Menu from '@/theme/components/Header/HeaderBottom/Menu';
const ifNull = (...arr) => arr.filter((a) => !!a).join(' ');

const MobileNavTabContent = ({ tab }) => {
  if (tab == 0) return <Menu />;
  if (tab == 1) return <AccountLinks />;
  return null;
};

const MobileNav = () => {
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [{ mitter }] = useAppContext();
  useEffect(() => {
    function fn() {
      setOpen(!open);
    }
    mitter.on('TOGGLE_NAV', fn);
    return () => mitter.off('TOGGLE_NAV', fn);
  }, [open]);

  return (
    <>
      {open && <div className='backdrop' onClick={() => setOpen(false)}></div>}
      <section
        className={clsx('nav-wrapper', {
          open,
        })}
      >
        <ul className='nav-items'>
          <li
            className={ifNull(tab == 0 && 'active')}
            onClick={(e) => {
              setTab(0);
            }}
          >
            Menu
          </li>
          <li
            className={ifNull(tab == 1 && 'active')}
            onClick={(e) => {
              setTab(1);
            }}
          >
            Account
          </li>
          {/* <li
            className={ifNull(tab == 2 && 'active')}
            onClick={(e) => {
              setTab(2);
            }}
          >
            Settings
          </li> */}
        </ul>
        <MobileNavTabContent tab={tab} />
      </section>
    </>
  );
};

export default function HeaderBottom(props) {
  const [{ isMobile }] = useAppContext();
  if (isMobile) {
    return (
      // <div className='header-bottom'>
        <MobileNav />
      // </div>
    );
  }
  return (
    <div className='header-bottom'>
      <Menu />
    </div>
  );
}
