export const Image = ({
  url = null,
  label = null,
  width = null,
  dimWidth = null,
  className = '',
  onClick = null,
  loading = 'lazy'
}) => {
  url =
    dimWidth && url
      ? url + (url.indexOf('?') > 0 ? '&' : '?') + 'width=' + dimWidth
      : url

  return (
    <picture onClick={onClick} className="picture">
      {/* Handle Webp */}
      {/* <source srcSet={url + ".webp"} type="image/webp" /> */}
      <img
        src={url}
        alt={label}
        className={className}
        width={width}
        loading={loading as 'lazy' | 'eager'}
      />
    </picture>
  )

  {
    /* return <img 
    width={width} 
    className={className} 
    src={src} alt={alt} 
    onClick={onClick}
    />; */
  }
}
